<template>
<div  class="ttcontainer">
  <MainNav /> 

  <input class="csInput" v-model="newEventName" placeholder="new Event Name ..... "> 
          	<div class="button" id="newEventButton" @click="callNewEventByName()">make new Event</div>

    <div id="eventList"  class="eventMainWin"
        v-if="!!ok" >

      <div class="hs-row" v-for="(item, index) in eventList" :key="index" >
                  
          <Event :currentEvent="item"></Event>

      </div>
    </div>

  </div>
</template>


<script>
import {mapGetters} from "vuex";
import { mapActions } from "vuex";
import MainNav from '@/components/Navi.vue'; // @ is an alias to /src
import Event from '@/components/Event.vue'; // @ is an alias to /src


export default {
  name: 'About',

  components: {
    MainNav,
    Event,
  },

  data() {
    return {
      ok: false,

      showDeleteOneWin: false,
      showEditWin : false,
      showHsWin: false,
            new_Event_name : "",
            new_Event_initscore : 0, 
             newEventName:"",
             showMore: false,
             showHsWin:   false,
             nrlist: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20],

      currentEvent: null,
      newEventName: '',
    }
  },

   computed:{
     ...mapGetters([
       'eventList',
     ]),
   },
   async mounted() {
     await this.getEvents()
     this.ok = true
   },

   methods: {
    
    ...mapActions([
      'getEvents',
      'newEventByName',
    ]),

    showHSByEvent: function (_event) {
      console.log("showHS -> ", _event)
      this.currentEvent = _event
      this.showHsWin = true
    },

    callNewEventByName: async function() {
      await this.newEventByName(this.newEventName)
    }

  },

}

</script>

<style scoped lang="scss">

div {
  color: blueviolet;
  font-size: 1rem;
}
  
#eventContainer {
        position: relative;
        display: flex;
}

.csInput{
      padding: 1vw 1vw;
    font-size: 1rem;
    margin: 1vw;
}

#eventList {
   padding: 2vw 2vw;   
    font-size: 1rem; 
    margin: 2vw; 
    background-color: honeydew;
    position: relative;
    max-height: 60vh;
    overflow: scroll;
  .eventName {
    min-width: 20rem;
   
  }

  div {
     padding-top: 1vh;
  }

  .deleteOneContainer {
    padding: 0;
    div {
       padding: 0;

    }
  }

}

</style>
