<template>
  <div id="WindparkAllianz"
    v-on:click.right.prevent >

    <StaticStart
      v-if="currentStep == STEP_WAITING"
      @nextStep="nextStep"
    ></StaticStart>
		
    <InsertName
      v-if="currentStep == STEP_INSERTNAME && currentView == VIEW_START"
      @nextStep="nextStep"
      @insertPlayerName="setPlayerName"
      @reset="reset"
      :windparkSettings="windparkSettings"
    ></InsertName>
    
    <Exponat2 
      v-if="(currentView == VIEW_PLAYING)"
      key="exponat2"
      ref="windpark"
      :player="myLocalPlayer"
      :currentStep="currentStep"
      :gewinnStufen="gewinnStufen"
      :windparkSettings="windparkSettings"
      @nextStep="nextStep"
      class="scaleScreen"
    >
    </Exponat2>

    <ExponatResultScreen 
      v-if="(currentStep == STEP_AUSWERTUNG)"
      :player="myLocalPlayer"
      :windparkSettings="windparkSettings"
      class="scaleScreen"
    ></ExponatResultScreen>

    <RangListe
      v-if="(currentStep == STEP_TOPTEN)"
      class="rangInPage"
    ></RangListe>

    <div id="debugNextStep"
      v-if="!!windparkSettings.debug"
      @click="nextStep()">
      nextStep: {{currentStep}}
    </div>

	</div>
</template>

<script >
  import {mapGetters} from "vuex"
  import {mapActions } from "vuex";

  import InsertName from '@//views/windpark/components/InsertName.vue'; // @ is an alias to /src
  import Exponat2 from '@//views/windpark/components/Exponat2.vue'; // @ is an alias to /src
  import ExponatResultScreen from '@//views/windpark/components/ExponatResultScreen.vue'; // @ is an alias to /src
  import RangListe from '@//views/windpark/AllianzRanglisteTotal.vue'; // @ is an alias to /src
  import StaticStart from '@//views/windpark/AllianzStaticStart.vue'

export default {
  name: 'AllianzWindPark',
  
  components: {
    InsertName,
    Exponat2,
    ExponatResultScreen,
    RangListe,
    StaticStart,
  },

  data() { 
    return {
      //debug: false,

      currentPlayTime: 0, // [s]
      playerTotalPower: 0,

      myId: 23,

      interval: null,
      myTimer: null,
     
      STEP_WAITING    : 0,
      STEP_INSERTNAME : 1,
      STEP_PLAYERINIT : 2,
      STEP_PLAYING 		: 3,
      STEP_AUSWERTUNG : 4,
      STEP_TOPTEN     : 5,

      currentStep: 0,

      VIEW_WAITING: 0,
      VIEW_START: 1,
      VIEW_COUNTDOWN: 2,
      VIEW_PLAYING: 3,
      VIEW_RESULTS: 4,
      VIEW_FINISH: 5,
      VIEW_RANGLISTE: 6,
      
      startView :   1,
      currentView: 0,

      windparkSettings: {
        VERSION: 'Allianz Verkauf 1.1.0',
        debug: false,

        spenden: true,
// highscoreServer: 'tentable.de:8900/highscore/',
        timeToShowEndScreen: 10000,
        timeToShowRangListe: 10000,
        intervalTimeToReCallArduinoData: 250, 
        redirectURL: 'https://www.nachhaltiger-als-du-denkst.de/?slug=dkm2022-tablet-1-auswahl&preview_token=a5d99c4b6d1f400',
        startScreenLink: 'https://www.nachhaltiger-als-du-denkst.de/?slug=dkm-2022-aktionstool-1&preview_token=9398c1ba0378403',
        link5Euro: "https://www.nachhaltiger-als-du-denkst.de/?slug=windpark-spendenlevel1&preview_token=a75501181f2f480",
        link10Euro: "https://www.nachhaltiger-als-du-denkst.de/?slug=windpark-spendenlevel2&preview_token=d1542d5f431a4c8",
        link15Euro: "https://www.nachhaltiger-als-du-denkst.de/?slug=windpark-spendenlevel3&preview_token=207cb47a23984b3",
      },



      gewinnStufen:  [
        {
          nr: 1,
          ws: 0,
          wsToShow: 0,
          euro: 5,
        }, {
          nr: 2,
          ws: 4000,
          wsToShow: 4,
          euro: 10,
        }, {
          nr: 3,
          ws: 8000,
          wsToShow: 8,
          euro: 15,
        }, {
          nr: 4,
          ws: 114000,
          wsToShow: 12,
          euro: "",
        }
      ],

      totalWsCount: 1,
      myLocalPlayer: {
        playerName: "noName"
      },
      playerName: "nix",
    }
  },

  created () {
  },

  mounted () {
    this.reset()
    this.setA1ToWattFaktor(2)
   },

  computed:{
      ...mapGetters([
        'axiosHeader',
        'eSum',
      ]),
  },

  /** 
   */
  methods: {
    ...mapActions([
      'getArduinoDirectIP',
      'setEnergyHarvesting',
      'resetEnergy',
      'setA1ToWattFaktor',
      'saveHSWindPark',
      'setShowHighscore',
    ]),

    reset: function () {
      this.setEnergyHarvesting(false)
      this.resetEnergy()

        clearInterval(this.timerA)
        clearInterval(this.interval)
        clearTimeout(this.myTimer)

      console.log(this.$options.name + " -> reset : currentStep: " + this.currentStep)
      // wenn ohne spenden ... dann hier anfangen 
      // this.processRemoteGameStats(this.STEP_WAITING)
      
      // wenn mit : 
      this.processRemoteGameStats(this.STEP_INSERTNAME)
    },

    /** 
     * @deprecated 
     */
    toggleStartScreen: function() {
      if (this.currentView == 101) {
        this.currentView = 102
      } else if (this.currentView == 102) {
        this.currentView = 103
      } else {
        this.currentView = 101
      }
    },

    /** make Highscore object for sending to server  
     * 
     * also used as localPlayer .. 
    */
    makeHSObject: function() {
      let hsObject = {
        playerName: this.playerName,
        eventName: "allianz",
        points: this.eSum,
        totalPower: this.eSum,
        spendenSumme: this.getSpendenSummeByPower(this.eSum)
      }
      console.log(this.$options.name + " -> makeHSObject : ", hsObject)
      return hsObject
    },

    nextStep: function() {
        let nextS = this.currentStep + 1;
        if (nextS > this.STEP_TOPTEN) {
          this.reset()
        } else {
          this.processRemoteGameStats(nextS)
        }
    },

    setStep: function(neueStepNr) {
      this.processRemoteGameStats(neueStepNr)
    },
	
    /** getGameStats: function () {
   //   console.log(this.$options.name + " -> checkForStart", this.countWaitingsUrl)
      const payload = { id: this.myId }
      const url = this.windparkSettings.windparkBaseUrl + "getgamestats"
       let response = axios.post(url, payload, this.axiosHeader)
        .then(response => this.processRemoteGameStats(response.data))
        .catch(err => { console.log(err.response); });
    },*/

    
    processRemoteGameStats: function(_data) {
      console.log(this.$options.name + " -> processRemoteGameStats -> begin : ", _data)
  
      let newStep = _data // windpark.currentStepNr
 //      if (!!this.debug) {console.log(" this.currentStep : " + this.currentStep + " -- remoteStep :  " + remoteStep ) }

      if (this.currentStep != newStep) {
        // so if step changed . 
        console.log(this.$options.name + " this.currentStep NOT remoteStep :  " )
        console.log(this.$options.name + " this.currentStep : " + this.currentStep + " -- remoteStep :  " + newStep)

        clearInterval(this.timerA)
        clearInterval(this.interval)
        clearTimeout(this.myTimer)

        switch (newStep) {
          case this.STEP_WAITING:
            this.currentView = this.VIEW_WAITING
            break;
          
          case this.STEP_INSERTNAME:
            this.currentView = this.VIEW_START
            break;
          case this.STEP_PLAYERINIT:
       //      console.log(this.$options.name + " -> processRemoteGameStats() -> STEP_PLAYERINIT -> playerName -> ", _data.windpark.player.name)
            this.interval = setInterval(this.getArduinoDirectIP, this.windparkSettings.intervalTimeToReCallArduinoData); 
            this.currentView = this.VIEW_PLAYING
            break;
          case this.STEP_PLAYING:
            this.setEnergyHarvesting(true)
            this.interval = setInterval(this.getArduinoDirectIP, this.windparkSettings.intervalTimeToReCallArduinoData); 
            this.myTimer = setTimeout(this.$refs.windpark.startTimer, 200)
            break;
          case this.STEP_AUSWERTUNG:
            this.setEnergyHarvesting(false)
            clearInterval(this.interval)

            // TODO .... total dreckig und nicht failSave ... 
            this.myLocalPlayer = this.makeHSObject()

            // DEBUG 
            if (this.windparkSettings.debug === true) {
              this.myLocalPlayer.points = this.myLocalPlayer.points + 1000
            }

            this.saveHSWindPark(this.myLocalPlayer)
            this.setShowHighscore(false)

            this.currentView = this.VIEW_RESULTS
            console.log(this.$options.name + " -> processRemoteGameStats -> STEP_AUSWERTUNG ->  " )
            
            // wenn spenden - dann spendenseite von Particulate ... 
            if (!this.windparkSettings.spenden) {
              // wenn keine spenden ... dann rangliste ... 
              this.currentView = this.VIEW_RANGLISTE
              this.myTimer = setTimeout(this.redirectToHighScore, this.windparkSettings.timeToShowEndScreen)
            } else {
               // wenn spenden ... dann Parti ... 
              this.myTimer = setTimeout(this.redirectToParticulate, this.windparkSettings.timeToShowEndScreen)
            }
            break;
            case this.STEP_TOPTEN:
              this.myTimer = setTimeout(this.reset, this.windparkSettings.timeToShowRangListe)
              break
          default:
            console.log(` -> windPark INdex -> FAILURE -> Sorry, we are out of STEPS : ${newStep}.`);
        }
        // und neuen Step setzen
        this.currentStep = newStep
      } else {
        console.log(this.$options.name + " -> processRemoteGameStats -> ACHTUNG !!!! NEUER STEP = ALTER STEP  ->  " + newStep )
      }
    },

    redirectToHighScore: function () {
      this.currentView = this.VIEW_RANGLISTE
      this.nextStep()
    },

    /** zur SpendenSeite  */
    redirectToParticulate: function () {
        // alt - nur ein link zur SummenAuswahl 
        // window.location.href = this.windparkSettings.redirectURL;
        let summe = this.getSpendenObjByPower(this.eSum)
        console.log(this.$options.name + " -> redirectToParticulate -> summe  ->  ", summe )
        if (summe.nr === 1) {
          window.location.href = this.windparkSettings.link5Euro;
        } else  if (summe.nr === 2) {
          window.location.href = this.windparkSettings.link10Euro;
        }  if (summe.nr === 3) {
          window.location.href = this.windparkSettings.link15Euro;
        }
    },

    redirectToParticulateStartScreen: function () {
      window.location.href = this.windparkSettings.startScreenLink;
    },

    setPlayerName: function(res) {
      console.log(this.$options.name + " -> setPlayerName ", res)
      this.playerName = res
      this.myLocalPlayer =  this.makeHSObject()
    },

    getSpendenSummeByPower: function(_p) {
      let spendenSumme = 0
      if (_p < this.gewinnStufen[1].ws) {
        spendenSumme =  this.gewinnStufen[0].euro
      } else 
      if (_p < this.gewinnStufen[2].ws) {
        spendenSumme =  this.gewinnStufen[1].euro
      } else {
        spendenSumme =  this.gewinnStufen[2].euro
      }
      return spendenSumme
    },

    getSpendenObjByPower: function(_p) {
      let retval = {}
      if (_p < this.gewinnStufen[1].ws) {
        retval =  this.gewinnStufen[0]
      } else 
      if (_p < this.gewinnStufen[2].ws) {
        retval =  this.gewinnStufen[1]
      } else {
        retval =  this.gewinnStufen[2]
      }
      return retval
    },

  } // end Methods 
}

</script>

<style scoped lang="scss" >

.rangInPage {
	position: absolute;
}

#WindparkAllianz{
  -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  -o-user-select: none;
  user-select: none;

  

  background-size: contain;
    background-image: url('/img/allianzWindpark/Background_gradient_FHD.png');
}

.okButton {
  position: absolute;
  top: 7vh;
  right: 7vw;
  z-index: 100;
  width: 15vw;
  min-width: none !important;
}  


.scaleScreen   {
  position: absolute;
  width: 100vw;
  height: 100vh;
  transform:scale(0.67);
  transform-origin: top left;
}

#screen3 {
  color: #003781;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 0.65;

  #line1 {
    position: absolute;
    top: 26.85vh;
    left: 21.08vw;
    width: 57.84vw;
    height: 7.65vh;
    text-align: center;
    letter-spacing: 0;
    font-size: 6.17vh;
    opacity: 1;

    #playername {
      font-weight: bolder;
    }
  }

  #line2 {
    position: absolute;
    top: 45.06vh;
    left: 7.88vw;
    width: 84.24vw;
    height: 16.05vh;
    text-align: center;
    letter-spacing: 0;
    font-size: 11.48vh;
    font-weight: bold;
    opacity: 1;
  }

  #line3 {
    position: absolute;
    top: 71.67vh;
    left: 17.53vw;
    width: 64.93vw;
    height: 7.65vh;
    text-align: center;
    letter-spacing: 0;
    font-size: 6.17vh;
    opacity: 1;
  
    #spendensumme {
      font-weight: bolder;
    }
  }
}

#debugNextStep {
  position: absolute;
  top: 0;
  z-index: 2000;
  width: auto;
  background-color: goldenrod;
  margin: 1vh;
  padding: 0.5vh;
  min-width: 0;
}

#Rectangle_59_br {
		fill: rgba(0,122,179,1);
	}


#Button_Spiel_starten {
		position: absolute;
		width: 416px;
	//	height: 106px;
		left: 8vw;
		bottom: 10vh;
		overflow: visible;
		padding: 25px 45px 25px 45px;
    opacity: 0.5;

    transform:scale(0.67);
    transform-origin: top left;
	}

  #Spiel_Starten {
		left: 52px;
	//	top: 19px;
		position: absolute;
		overflow: visible;
		width: 313px;
		white-space: nowrap;
		text-align: center;
		font-family: Allianz Neo;
		font-style: normal;
		font-weight: bold;
		font-size: 45px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.3px;
		text-transform: uppercase;
	}
  
</style>
