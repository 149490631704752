<template>

	
<div id="Group_16">
	<div id="Group_6" v-for="(digit, index) in zahlenArray" :key="digit.id">
		<span v-if="index == zahlenArray.length - nachkommastellen" class="dot"> . </span>
		<div id="Group_5">
			<svg class="Rectangle_4">
				<rect id="Rectangle_4" rx="0" ry="0" x="0" y="0" width="200" height="130">
				</rect>
			</svg>
			<svg class="Rectangle_5">
				<rect id="Rectangle_5" rx="0" ry="0" x="0" y="0" width="200" height="130">
				</rect>
			</svg>
		</div>
		<div id="n_">
			<span>{{digit}}</span>
			<!-- <span>{{String(digits).slice(-1) || 0}}</span> -->
		</div>
	</div>
	<!-- <div id="Group_8">
		<div id="Group_5_bl">
			<svg class="Rectangle_4_bm">
				<rect id="Rectangle_4_bm" rx="0" ry="0" x="0" y="0" width="200" height="130">
				</rect>
			</svg>
			<svg class="Rectangle_5_bn">
				<rect id="Rectangle_5_bn" rx="0" ry="0" x="0" y="0" width="200" height="130">
				</rect>
			</svg>
		</div>
		<div id="n__bo">
			<span>{{String(digits).slice(-3, -2) || 0 }}</span>
		</div>
	</div>
	<div id="Group_9">
		<div id="Group_5_bq">
			<svg class="Rectangle_4_br">
				<rect id="Rectangle_4_br" rx="0" ry="0" x="0" y="0" width="200" height="130">
				</rect>
			</svg>
			<svg class="Rectangle_5_bs">
				<rect id="Rectangle_5_bs" rx="0" ry="0" x="0" y="0" width="200" height="130">
				</rect>
			</svg>
		</div>
		<div id="n__bt">
			<span>{{String(digits).slice(-4, -3)  || 0 }}</span>
		</div>
	</div>
	<div id="Text">
		<span>.</span>
	</div> -->
</div>

</template>

<script>
import {mapGetters} from "vuex"

export default {
	name: 'Zahlen',
	props: ['digits', 'nachkommastellen', 'anzahl'],

	components: {
	},

	data () {
		return {
			spendenSumme: "10,00 Euro",
			zahlenString: "",
		}
	},

	created() {
		//this.convertNumberToArray(this.digts)

		if (!this.nachkommastellen) {
			this.nachkommastellen = 0
		} else {
			//
		}
	},

	computed:{
		...mapGetters([
			'eSum',
		]),
		zahlenArray(){
			let arr = [];
			this.zahlenString = String(this.digits);
			let kommaCount = this.nachkommastellen
/* 			let stringLength = this.zahlenString.length;
			while(stringLength < 5){
				arr.push(0);
				stringLength++;
			} */
			let nachDemKomma = false;
			for (let digit of this.zahlenString) {
 				if ( digit == ".") {
					nachDemKomma = true;
				} else { 
					arr.push(digit);
					if (nachDemKomma){
						if (kommaCount <= 0 ) {
							break;
						} else {
							kommaCount--
						}
					}
				}
			}

			// vorne mit Nullen auffüllen 
 			while(arr.length < this.anzahl){
				arr.unshift(0);
			} 
			// und action 
			return arr;
		}
	},

	mounted() {


		// console.log(this.$options.name + "-> totalWs = " + this.player.points)
		// console.log(this.$options.name + "-> Spendensumme = " + this.player.spendenSumme)
		//   this.calculateSum();
	},

  methods:{

	convertNumberToArray: function() {
		this.zahlenString = String(this.digits)
		console.log(this.$options.name + "-> ZahlenString: " + this.zahlenString)
		// this.zahlenArray = [ ...this.zahlenString]
		for (let digit of this.zahlenString) {
			console.log(Number(digit))
			if ( isNaN(Number(digit)) ) {
				break } else {
			this.zahlenArray.push(digit)
			}
		}
		console.log(this.$options.name + "-> ZahlenArray: " + this.zahlenArray)
	},

	calculateSum: function() {
		if (this.total >= 20) {
			this.spendenSumme = "20,00 Euro";
		}
		if (this.total >= 40) {
			this.spendenSumme = "30,00 Euro";
		}
	}	  
  },
}

</script>

<style scoped lang="scss">

.mediaViewInfo {
		--web-view-name: Exponat 3;
		--web-view-id: Exponat_3;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: Exponat_3;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#Exponat_3 {
		position: absolute;
		width: 2880px;
		height: 1620px;
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: Exponat 3;
		--web-view-id: Exponat_3;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#P307_Windraeder_HKZ_144dpi_2K {
		position: absolute;
		width: 3399px;
		height: 2266px;
		left: 0px;
		top: -360px;
		overflow: visible;
	}
	#Rectangle_3 {
		opacity: 0.648;
		fill: rgba(255,255,255,1);
	}
	.Rectangle_3 {
		position: absolute;
		overflow: visible;
		width: 2880px;
		height: 1620px;
		left: 0px;
		top: 0px;
	}
	#AZ_Logo_positive_RGB {
		position: absolute;
		width: 479.844px;
		height: 119.091px;
		left: 160px;
		top: 134px;
		overflow: visible;
	}
	#Path_1 {
		fill: rgba(0,55,129,1);
	}
	.Path_1 {
		overflow: visible;
		position: absolute;
		width: 72.271px;
		height: 77.433px;
		left: 0px;
		top: 16.928px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_2 {
		fill: rgba(0,55,129,1);
	}
	.Path_2 {
		overflow: visible;
		position: absolute;
		width: 23.65px;
		height: 77.553px;
		left: 72.151px;
		top: 16.928px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_3 {
		fill: rgba(0,55,129,1);
	}
	.Path_3 {
		overflow: visible;
		position: absolute;
		width: 23.65px;
		height: 77.553px;
		left: 103.124px;
		top: 16.928px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_4 {
		fill: rgba(0,55,129,1);
	}
	.Path_4 {
		overflow: visible;
		position: absolute;
		width: 23.89px;
		height: 78.873px;
		left: 134.697px;
		top: 15.486px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_5 {
		fill: rgba(0,55,129,1);
	}
	.Path_5 {
		overflow: visible;
		position: absolute;
		width: 54.143px;
		height: 59.665px;
		left: 166.631px;
		top: 35.055px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_6 {
		fill: rgba(0,55,129,1);
	}
	.Path_6 {
		overflow: visible;
		position: absolute;
		width: 56.064px;
		height: 58.824px;
		left: 223.895px;
		top: 35.535px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_7 {
		fill: rgba(0,55,129,1);
	}
	.Path_7 {
		overflow: visible;
		position: absolute;
		width: 45.62px;
		height: 58.104px;
		left: 288.242px;
		top: 36.256px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_8 {
		fill: rgba(0,55,129,1);
	}
	.Path_8 {
		overflow: visible;
		position: absolute;
		width: 119.091px;
		height: 119.091px;
		left: 360.753px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_9 {
		fill: rgba(0,0,0,0);
		stroke: rgba(0,55,129,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Path_9 {
		overflow: visible;
		position: absolute;
		width: 1px;
		height: 1px;
		left: 1749px;
		top: 989.75px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Sie_knnen_3000_Euro_als_Gewinn {
		left: 468px;
		top: 1148px;
		position: absolute;
		overflow: visible;
		width: 1945px;
		white-space: nowrap;
		text-align: center;
		font-family: Allianz Neo;
		font-style: normal;
		font-weight: normal;
		font-size: 100px;
		color: rgba(0,55,129,1);
	}
	#Herzlichen_Glckwunsch_FuturePl {
		left: 584px;
		top: 422px;
		position: absolute;
		overflow: visible;
		width: 1713px;
		white-space: nowrap;
		text-align: center;
		font-family: Allianz Neo;
		font-style: normal;
		font-weight: normal;
		font-size: 100px;
		color: rgba(0,55,129,1);
	}
	#Group_17 {
		position: absolute;
		width: 2420px;
		height: 260px;
		left: 229px;
		top: 730px;
		overflow: visible;
		
	}
	#Sie_haben {
		left: 0px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 649px;
		white-space: nowrap;
		text-align: center;
		font-family: Allianz Neo;
		font-style: normal;
		font-weight: bold;
		font-size: 150px;
		color: rgba(0,55,129,1);
	}
	#Watt_erzeugt {
		left: 1524px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 897px;
		white-space: nowrap;
		text-align: center;
		font-family: Allianz Neo;
		font-style: normal;
		font-weight: bold;
		font-size: 150px;
		color: rgba(0,55,129,1);
	}
	#Group_16 {
		position: absolute;
		width: 674px;
		height: 260px;
		left: 748px;
		top: 0px;
		overflow: visible;
		white-space: nowrap;
	}
	
	#Group_16 > div:last-child{
		left: 50px;
	}
	
	#Group_6 {
		display: inline-block;
		position: relative;
		width: 200px;
		height: 260px;
		margin-right: 20px;
		// left: 474px;
		top: 0px;
		overflow: visible;
		white-space: nowrap;
	}
	#Group_5 {
		position: absolute;
		width: 200px;
		height: 260px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Rectangle_4 {
		fill: rgba(0,122,179,1);
	}
	.Rectangle_4 {
		position: absolute;
		overflow: visible;
		width: 200px;
		height: 130px;
		left: 0px;
		top: 0px;
	}
	#Rectangle_5 {
		fill: rgba(0,55,129,1);
	}
	.Rectangle_5 {
		position: absolute;
		overflow: visible;
		width: 200px;
		height: 130px;
		left: 0px;
		top: 130px;
	}
	#n_ {
		opacity: 0.902;
		left: 0px;
		top: 13px;
		position: absolute;
		overflow: visible;
		width: 201px;
		height: 246px;
		text-align: center;
		font-family: Allianz Neo;
		font-style: normal;
		font-weight: bold;
		font-size: 185px;
		color: rgba(255,255,255,1);
	}
	#Group_8 {
		position: absolute;
		width: 200px;
		height: 260px;
		left: 220px;
		top: 0px;
		overflow: visible;
	}
	#Group_5_bl {
		position: absolute;
		width: 200px;
		height: 260px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Rectangle_4_bm {
		fill: rgba(52,120,174,1);
	}
	.Rectangle_4_bm {
		position: absolute;
		overflow: visible;
		width: 200px;
		height: 130px;
		left: 0px;
		top: 0px;
	}
	#Rectangle_5_bn {
		fill: rgba(0,55,129,1);
	}
	.Rectangle_5_bn {
		position: absolute;
		overflow: visible;
		width: 200px;
		height: 130px;
		left: 0px;
		top: 130px;
	}
	#n__bo {
		opacity: 0.902;
		left: 0px;
		top: 13px;
		position: absolute;
		overflow: visible;
		width: 201px;
		height: 246px;
		text-align: center;
		font-family: Allianz Neo;
		font-style: normal;
		font-weight: bold;
		font-size: 185px;
		color: rgba(255,255,255,1);
	}
	#Group_9 {
		position: absolute;
		width: 200px;
		height: 260px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Group_5_bq {
		position: absolute;
		width: 200px;
		height: 260px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Rectangle_4_br {
		fill: rgba(0,122,179,1);
	}
	.Rectangle_4_br {
		position: absolute;
		overflow: visible;
		width: 200px;
		height: 130px;
		left: 0px;
		top: 0px;
	}
	#Rectangle_5_bs {
		fill: rgba(0,55,129,1);
	}
	.Rectangle_5_bs {
		position: absolute;
		overflow: visible;
		width: 200px;
		height: 130px;
		left: 0px;
		top: 130px;
	}
	#n__bt {
		opacity: 0.902;
		left: 0px;
		top: 13px;
		position: absolute;
		overflow: visible;
		width: 201px;
		height: 246px;
		text-align: center;
		font-family: Allianz Neo;
		font-style: normal;
		font-weight: bold;
		font-size: 185px;
		color: rgba(255,255,255,1);
	}
	#Text {
		opacity: 0.902;
		left: 398px;
		top: 28px;
		position: absolute;
		overflow: visible;
		width: 97px;
		height: 219px;
		text-align: center;
		font-family: Allianz Neo;
		font-style: normal;
		font-weight: bold;
		font-size: 165px;
		color: rgba(0,0,0,1);
	}
	
	.dot{
		position: absolute;
		left: -65px;
		top: -90px;
		font-size: 300px;
		color: black;
	}
	
</style>
