<template>
  <div id="AlcatelRouterContainer" class="ttcontainer">
      <h2
           @click="myDevice.showDevice = !myDevice.showDevice">
        Alcatel UMTS Router
      </h2>

      <div v-if="myDevice.showDevice == true" >

      <div class="twoRows">
        <div class="ttRow">
          IP {{myDevice.deviceType}}: 
          {{myDevice.deviceIP}}

          <!-- <div class="button"
              @click="powerOn(myDevice)">
            an
          </div>
          <div class="button"
              @click="powerOff(myDevice)" >
            aus
          </div> -->
        </div>

        <div class="ttRow"
             @click="myDevice.showFrame = !myDevice.showFrame">
          <img src="img/alcatel_router.png" class="deviceImg" />
        </div>
        <div class="ttRow">
          <iframe 
               v-if="myDevice.showFrame == true" 
               :src="myDevice.deviceUrl" class="deviceIFrame" />
        </div>
        
      </div>

    </div>
  </div>

  
</template>

<script>
  import {mapGetters} from "vuex";
  import { mapActions } from "vuex";
  import axios from 'axios';  

export default {
  name: 'RouterAlcatel',

   data() {
    return {
      myDevice: {
          deviceName: '??? Box',
          deviceType: 'Alcatel Router',
          deviceFeatures: [
            'UMTS',
            'DHCP',
          ],
          deviceIP: "10.116.1.1",
          deviceUrl: 'http://10.116.1.1',
          deviceResponse: 'no response',
          deviceError: '',
          showDevice: false,
          showFrame: false,
      },
      requestOptions: {
         headers: {
          },
      },
    }
  },

computed: {
    ...mapGetters([
    ]),
  },

  // mounted() {
  //   this.loopGetArduinoData()
  // },

  methods: {
    ...mapActions([
    ]),
  },

}

</script>

<style scoped>

  .deviceIFrame {
    width: 40vw;
    height: 50vh;
  }

  #AlcatelRouterContainer{
    background-color: #f4c1;
  }

</style>
