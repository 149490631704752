<template>
    <div id="EthernetPatchBay" class="ttcontainer">
      <h2
            @click="device.showDevice = !device.showDevice">
        EthernetPatchBay 
      </h2>
      <div v-if="device.showDevice == true" >
         <ul>
          <li v-for="(a, index) in patchbaySettings" :key="index" >
            {{a.nr}} - {{a.connection}}
          </li>
        </ul>
      </div>
    </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import { mapActions } from "vuex";

export default {
  name: 'EthernetPatchBay',

   data() {
    return {
      device: {
        deviceName: 'EthernetPatchBay',
        deviceType: 'PatchBay',
        showDevice: false,
      },
      patchbaySettings: [
          {nr: 5, connection: 'Arduino L'},
          {nr: 6, connection: 'Arduino M'},
          {nr: 7, connection: 'Arduino R'},
          {nr: 14, connection: 'Alcatel Router'},
          {nr: 15, connection: 'Raspi'},
          {nr: 24, connection: 'WebRelais1'},
      ],
    }
  },

computed: {
    ...mapGetters([
    ]),
  },

  // mounted() {
  //   this.loopGetArduinoData()
  // },

  methods: {
    ...mapActions([
    ]),
  },

}

</script>

<style scoped>

</style>
