<template>
  <div>
    <GluecksRad :config="wheeldata" />
  </div>
</template>

<script>
import GluecksRad from "@/components/GluecksRad.vue"
import { mapGetters } from "vuex";
import { mapActions } from "vuex";

export default {
  name: 'GluecksRadMaster',

  components: {
    GluecksRad,
  },

  data() {
    return {
    }
  },

  created() {
      this.loadLuckyFromLocalStorage();
  },

  mounted() {
    console.log(this.$options.name + " -> mounted");
  },

  computed: {
    ...mapGetters([
       'wheeldata',
    ]),
  },

  methods: {
    ...mapActions([
      'loadLuckyFromLocalStorage',
    ]),
  },
}
</script>

<style scoped>
</style>
