<template>
	<div id="rootDiv">

		<div id="levelBaumContainer" 
		class="level contentWin" 
		>

		<div 
			v-if="seite === 'links' && this.myTotalPower >= valueBaumVoll"
			id="linksGeschafft"
			class="geschafft">
		</div>
		<div 
			v-if="seite == 'rechts' && this.myTotalPower >= valueBaumVoll"
			id="rechtsGeschafft"
			class="geschafft">
		</div>
		<!-- ----- BAUM ------  -->
		<div class="baum" id="baumLeer"> 
			<div class="baum" id="baumvoll" :style="progressUpdate"   > 
			</div>
		</div>
		</div>

	</div>
</template>



<script>
  import {mapGetters} from "vuex";
  // import { mapActions } from "vuex";
 
  import Vue from 'vue'
  import VueConfetti from 'vue-confetti'

  Vue.use(VueConfetti)



export default {
  name: 'LeistungsRad',
  
  props: ["seite", "sensorPinNr"],

  components: {
  },


   data () {
    return {
		restzeit: -1,
		restzeitInS: -1,
		startzeit: null,
		time: null,
		myPower: 0,
		myTotalPower: 0,

		timeToShowConfetti: 4000,
		mode:'waiting', // waiting // running // konfetti // idle 

		valueBaumVoll: 15000,

		goalReached: false,

		
    }
  },

  computed:{
    ...mapGetters([
		"arduinoData",
	]),

	progressUpdate() {
		let h =  100 * (1 - (this.valueBaumVoll - this.myTotalPower) / this.valueBaumVoll);
		console.log(this.$options.name + " -> progressUpdate -> height: " + h + "%")
		return {
			height: `${100 * (1 - (this.valueBaumVoll - this.myTotalPower) / this.valueBaumVoll)}%`,
		};
    },

  },

  mounted() {
  },


  watch: {
    arduinoData: function (_neu, _alt) {
		const threashold = 40
	  console.log(this.$options.name + " -> watch -> ",_neu)

	  this.myPower = Math.round(_neu.analogIn[this.sensorPinNr])
	  if (this.myPower < threashold) {
		  this.myPower = 0;
	  }
	  if (this.mode === 'playing') {
		  this.myTotalPower = this.myTotalPower + this.myPower / 2
		  if (this.myTotalPower >= this.valueBaumVoll) {
			  // ok - ich hab den Baum 
			  console.log(this.$options.name + " -> watch -> Goal REACHED ")
			  this.setMode("konfetti")
		  }
	  }
    }
  },

  methods:{
	
		setMode: function (_mode) {
			console.log(this.$options.name + " -> setMode -> " + _mode)
			this.mode = _mode

			if (this.mode == "konfetti") {
				this.goalReached = true
				this.startConfetti()
			}
		},

		hasReachedGoal: function() {
			if (!!this.goalReached) {
				return true 	
			} else {
				return false
			}
		},
		
		startConfetti() { 				//elementID ist das Element auf dem Das Konfetti erzeugt werden soll
			console.log("seite = " + this.seite)
			this.loadCanvas(this.seite)
			var cID = 'confetti' + this.seite				//Canvas auf element erzeugen (siehe unten)
			this.$confetti.start({					//aufruf des Components
				canvasId: cID,				//ID des Canvas
				windSpeedMax: 2,					//Windsimulation (0 schaltet rotation nicht aus)
				particlesPerFrame: 2,			//Menge an Konfettis pro Frame
				dropRate: 30,						//geschwindigkeit des falls
				particles: [						//Einstellungen f�r die Konfetti-Partikel
					{
						type: 'circle',				//form des Konfetti, es gibt noch circle, rect und heart
						size: 10,						//gr��e der Konfetti
						//TODO: Im zweifel f�r n�chstes Projekt anpassen, mitFile-Path als �bergabe Parameter ODER settings FIle falls man mehr als einen "Typ" Konfetti hat, bsp: Kooperation von 2 Firmen die beide ihr logo wollen etc...
					//   url: '/img/johann/johann_logo.png',	//link falls type = image
					},
					],
					
			});
			setTimeout(this.stopConfetti, this.timeToShowConfetti)
		},

		stopConfetti: function() {							
			console.log("stopConfetti " + this.seite)
			this.$confetti.stop();
			this.setMode("idle")
		},

		love() {
			this.$confetti.update({					//Mit update kann man die parameter anpassen, dies ist nur eine beispielanwendung des Urhebers
				particles: [
				{
					type: 'heart',
				},
				{
					type: 'circle',
				},
				],
				defaultColors: [
				'red',
				'pink',
				'#ba0000'
				],
			});
		},
		
		loadCanvas(id) {
			var canvas = document.createElement('canvas'),		//canvas erzeugen
			div = document.getElementById(id);
			//var divKey = "div" + this.seite;
			// div.setAttribute("key", divKey);
			 					//referenz auf gew�nschtes Element
			console.log("loadCanvas -> div = ", div)									//wichtig da das canvas manchmal trotzdem au�erhalb des bildes erscheint (?)
			var s = "confetti" + this.seite; 
			console.log("Id = " + s)							//id descanvas (umbedingt so lassen)
			canvas.id     = s;							//id descanvas (umbedingt so lassen)
			canvas.width  = 960;			
			canvas.height = 1080;
			canvas.style.zIndex   = 80;							//wichtig da das canvas ansonsten au�erhalb des Bildschirms appended wird
			canvas.style.position = "absolute";					//wichtig da z-index sonst nicht geht
			canvas.style.width  = "100%";									//wichtig da das canvas manchmal trotzdem au�erhalb des bildes erscheint (?)
			canvas.style.height = "100%";						//wichtig da das canvas manchmal trotzdem au�erhalb des bildes erscheint (?)
			canvas.style.top = 0;
			let keyName = 'konf' + this.seite
			canvas.setAttribute("key", keyName);
			div.appendChild(canvas);	
			console.log("loadCanvas -> canvas = ", canvas)									//wichtig da das canvas manchmal trotzdem au�erhalb des bildes erscheint (?)
		}
  }

}

</script>

<style scoped lang="scss">

	.confettiCanvas {
		width: 100%;			
		height: 100%;
		z-index: 80;						//wichtig da das canvas ansonsten au�erhalb des Bildschirms appended wird
		position: absolute;					//wichtig da z-index sonst nicht geht
		top: 0;									//wichtig da das canvas manchmal trotzdem au�erhalb des bildes erscheint (?)
	}

	.rootDiv {
		position: relative;
	}

	.geschafft {
		position: absolute;
		top: 12.7vh;
		left: 10.5vw;
		height: 5.17%;
		width: 100%;
	}

	#linksGeschafft {
		background-image: url("/img/AlexaBike/geschafftlinks.png");
		background-size: contain;
		background-repeat: no-repeat;
	}

	#rechtsGeschafft {
		background-image: url("/img/AlexaBike/geschafftrechts.png");
		background-size: contain;
		background-repeat: no-repeat;
	}

	#baumLeer {
		background-image: url("/img/AlexaBike/Baum_transparent.png");
		position: relative;
		background-size: contain;
		margin-left: 30vw;
		width: 50vw;
	}

	#baumvoll {
		background-image: url("/img/AlexaBike/Baum_voll.png");
		position: absolute;
		width: 100%;
		bottom: 0;
		background-position-y: bottom;
	}

	.baum {
		text-align: center;
		background-repeat: no-repeat;
		height: 42vh;
	}

</style>
