<template>
<div  class="ttcontainer eventContainer">

	<div class="eventWrapper">
		        <div class="eventName" >   {{ currentEvent.name }} </div>

              <div @click="toggleShowSettings(currentEvent)"> [show Settings] </div>

              <div class="eventHS" v-if="currentEvent.hasHighScore">
                <div> HighScore:</div>
                   
                    <div @click="toggleShowHS(currentEvent)"> [show Top10] </div>
                    
                    <div @click="deleteHighScores(currentEvent.name)"> [clear] </div>
                    <div @click="newHighScores(currentEvent.name)"> [new] </div>
                    
                    <div @click="showDeleteOneWin=!showDeleteOneWin" >
                      delete: 
                    </div>
                  <div class="deleteOneContainer" v-if="!!showDeleteOneWin">
                    <div v-for="nr in nrlist" :key="nr">
                      <div v-on:click="deleteHsId(currentEvent, nr)"> {{nr}} </div>
                    </div>
					        </div>
              </div>
	</div>
              
    <div v-if="!!showHsWin" id="scores">
      <TopTen :eventName="currentEvent.name" ></TopTen>
    </div>
    <div v-if="!!showSettings" id="eventSettingsContainer">
      <textarea v-model="newEventSettings" rows="8" cols="40"></textarea>
        <pre>{{ newEventSettings | pretty }}</pre>
    </div>

  </div>	
</template>


<script>
import {mapGetters} from "vuex";
import { mapActions } from "vuex";
    import TopTen from '@/components/highscore/TopTen.vue'; // @ is an alias to /src


export default {
  name: 'Event',

  components: {
    TopTen,
  },
  props: ["currentEvent"],
  
  data() {
    return {
      ok: false,

      showDeleteOneWin: false,
      showEditWin : false,
      showHsWin: false,
      showSettings: false,
      new_Event_name : "",
      new_Event_initscore : 0, 
      newEventName: "",
      showMore: false,
      nrlist: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20],
      newEventSettings: "",
    }
  },

  filters: {
    pretty: function(value) {
 //     return JSON.stringify(value, null, 2);
    return JSON.stringify(JSON.parse(value), null, 2);
    }
  },

   computed:{
     ...mapGetters([
     ]),
   },
   async mounted() {
   },

   methods: {
    
    ...mapActions([
      'newHighScores',
      'deleteHighScores'
    ]),

    showHS: function () {
      console.log(" ->showHS -> ")
      this.showHsWin = true
    },
    toggleShowHS: function () {
      this.showHsWin = !this.showHsWin
      console.log(" ->toggleShowHS -> " + this.showHsWin)
    },
    toggleShowSettings: function () {
      this.showSettings = !this.showSettings
      this.newEventSettings = JSON.stringify(this.currentEvent.mySettings)
      console.log(" -> toggleShowSettings -> " + this.showSettings)
    },

  },


}

</script>

<style  lang="scss">

#eventContainer {
        position: relative;
        display: flex;
}

.csInput{
      padding: 1vw 1vw;
    font-size: 1rem;
    margin: 1vw;
}

.eventHeadline{
	color: blueviolet;
}

.hs-playerPoints{
	color: black;
}

.hs-playerName{
	color: black;
}

.hs-index{
	color: black;
}

#eventList {
   padding: 2vw 2vw;   
    font-size: 1rem; 
    margin: 2vw; 
    background-color: honeydew;
    position: relative;
  .eventName {
    min-width: 20rem;
   
  }
  
  .eventWrapper {
  	display: flex;
  }

  div {
     padding-left: 1vh;
  }

  .deleteOneContainer {
    padding: 0;
    div {
       padding: 0;

    }
  }

  .eventHS {
    display: flex;
  }

}

TopTen {
  color: black;
}

#eventSettingsContainer {
  display: flex;
}

</style>
