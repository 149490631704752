<template>
	<div id="Screen" class="Screen">
		<div id="Stopwatch">{{ restzeit }}</div>
	</div>
</template>
	
	
<script>

export default {
	name: 'SimpleStopWatch',

	data() {
		return {
			/** Länge des Countdowns in Millisekunden */
			countdowntime : 60000,

			/** Anzeigemodi: 1 = Sekunden : Hundertstel, 2 = Minuten : Sekunden : Zehntel, 3 = Minuten : Sekunden,
			else Minuten : Sekunden : Hundertstel */
			modus : 3,
			/** Nur Anfangszeit (countdowntime) */
			COUNTMODE_NOCOUNT : 0,
			/** Countdown mit Anfangszeit (countdowntime) */
			COUNTMODE_DOWN : 1,
			/** Stoppuhr mit Endzeit (countdowntime) */
			COUNTMODE_UP : 2,
			/** Stoppuhr ohne Endzeit (endless) */
			COUNTMODE_UP_ENDLESS: 3,

			countMode: 0,

			/** Soundfile hier einfügen 
			Testsounds
			Horn (testsound_horn.wav):  https://assets.mixkit.co/sfx/download/mixkit-truck-horn-720.wav
			Flute (testsound_flute.wav): https://assets.mixkit.co/sfx/download/mixkit-melodical-flute-music-notification-2310.wav
			*/
			soundfile: '/snd/testsound_flute.wav',

			/** sound auf true setzten, um Soundfile abzuspielen */
			sound : true,
			t : -1,
			minuten : -1,
			sekunden : -1, 
			zehntel: -1,  
			hundertstel: -1,
			time : null,
			running : 0, // 0 = ?? 1 = ??? 
			restzeit : -1, 
			startzeit : -1,
		}
	},

	/** Count-Mode hier einstellen (s.o) */
	created () {
		this.countMode = this.COUNTMODE_DOWN
	},

	mounted () {
		this.resetwatch()
		this.eventlistener()
	},

	methods: {
		/** Funktion wird durch Drücken von Leertaste oder Enter aufgerufen.
		Ruft die Funktion countdown() alle 50ms auf. */
		timer(event) {
			this.startzeit = new Date();
			this.time = setInterval(this.countdown, 50);
		},

		/** Wird durch die Funktion timer() aufgerufen.
		Zählt die Zeit und zeigt sie an */
		countdown() {
			const aktuellezeit = new Date(); 

		/* t = Zeit in Millisekunden
		Wenn countMode cauf COUNTMODE_UP oder COUNTMODE_UP_ENDLESS steht, wird t hoch gezählt, sonst runter */	
			if (this.countMode === this.COUNTMODE_UP || this.countMode === 3) {
				this.t = aktuellezeit.getTime() - this.startzeit.getTime();
			} else {
				this.t = this.countdowntime + this.startzeit.getTime() - aktuellezeit.getTime();
			}	
			
		/* Wenn Zeit abgelaufen oder countdownzeit erreicht ist, ertönt ein Sound (wenn vorhanden)
		und die Uhr kann mit Leertaste oder Enter zurückgestellt werden */
			if (this.t <= 0 || (this.t >= this.countdowntime && this.countMode === this.COUNTMODE_UP)) {
				this.stopcount()
				this.running++;
				if (this.countMode === this.COUNTMODE_UP) {
					this.t = this.countdowntime;
				} else if (this.countMode === 1) {
					this.t = 0;
			}
				
		/* trotzdem Fehler !!!
				let myAudioFile = null;
				try {
					myAudioFile = new Audio(soundfile);
					}
				catch(err) {
					console.log("Soundfile nicht gefunden!");
					}
				if (myAudioFile) {
				myAudioFile.play();
				} */
				
				if (this.sound === true) {
					new Audio(this.soundfile).play();
				}
			}
			
			if (this.t <= 0 ) {
				this.t = 0
			}

		/* Darstellung der Zeit je nach Anzeigemodus */
			this.minuten = Math.floor(this.t/60000);
			this.sekunden = Math.floor((this.t - this.minuten * 60000) / 1000);
			this.zehntel = Math.floor((this.t - (this.minuten * 60000) - (this.sekunden * 1000)) / 100);
			this.hundertstel = Math.floor((this.t - (this.minuten * 60000) - (this.sekunden * 1000) - (this.zehntel * 100)) / 10);
			if (this.minuten < 10) {
				this.minuten = "0" + this.minuten;
			}
			
			if (this.sekunden < 10) { 
				// UiUiUi ... aus int wird string ... böse ... :)
				this.sekunden = "0" + this.sekunden;
			}
			if (this.modus === 1) {
				this.restzeit = this.sekunden + ":" + this.zehntel + this.hundertstel;
			} else if (this.modus === 2) {
				this.restzeit = this.minuten + ":" + this.sekunden + ":" + this.zehntel;
			} else if (this.modus === 3) {
				this.restzeit = this.minuten + ":" + this.sekunden;	
			} else {
				this.restzeit = this.minuten + ":" + this.sekunden + ":" + this.zehntel + this.hundertstel;
			}
		},

		/** Wird durch zweites Drücken von Leertaste oder Enter aufgerufen wenn Zeit verbleibt.
		Hält die Uhr an */
		stopcount() {
			clearInterval(this.time);
		},

		/** Wird am Anfang und durch drittes Drücken von Leertaste oder Enter aufgerufen wenn Zeit verbleibt, sonst nach zweitem Drücken.
		Setzt die Uhr zurück */ 
		resetwatch() {
			this.running = 0;
			if (this.countMode === this.COUNTMODE_UP || this.countMode === 3) {
				this.t = 0;
			} else { 
				this.t = this.countdowntime;
			}
		/* Darstellung der Zeit je nach Anzeigemodus */
			this.minuten = Math.floor(this.t/60000);
			this.sekunden = Math.floor((this.t - this.minuten * 60000) / 1000);
			this.zehntel = Math.floor((this.t - (this.minuten * 60000) - (this.sekunden * 1000)) / 100);
			this.hundertstel = Math.floor((this.t - (this.minuten * 60000) - (this.sekunden * 1000) - (this.zehntel * 100)) / 10);
			if (this.minuten < 10) {
				this.minuten = "0" + this.minuten;
			}
			if (this.sekunden < 10) {  
				this.sekunden = "0" + this.sekunden;
			}
			if (this.modus === 1) {
				this.restzeit = this.sekunden + ":" + this.zehntel + this.hundertstel;
			} else if (this.modus === 2) {
				this.restzeit = this.minuten + ":" + this.sekunden + ":" + this.zehntel;
			} else if (this.modus === 3) {
				this.restzeit = this.minuten + ":" + this.sekunden;	
			} else {
				this.restzeit = this.minuten + ":" + this.sekunden + ":" + this.zehntel + this.hundertstel;
			}
		},

		/** Eventlistener, wird beim Mounten aufgerufen 
			je nach Status von "running" werden die Funktionen timer(), stopwatch() oder resetwatch() aufgerufen */
		eventlistener()	{
			window.addEventListener("keydown", this.myEventlistener).bind(this)
		},

		myEventlistener: function(event) {
				if (event.keyCode === 32 || event.keyCode === 13) {
					if (this.running === 1) {
						this.running++;
						this.stopcount();
					} else if (this.running === 0 && this.countMode != 0) {
						this.running++;
						this.timer();  
					} else {
						this.resetwatch();
					}
				}
			}
	} // end methods
}
 
</script>

<style scoped>

	.Screen {
		display: flex;
		width: 100vw;
		height: 100vh;
	/*  "flex-start" für links, "center" für mittig und "flex-end" für rechts */
		justify-content: center;
	/*  "flex-start" für oben, "center" für mittig und "flex-end" für unten */	
		align-items: center;
	}

	/* Testbild: Eigene Ressourcen einfügen!
	https://www.pcclean.io/wp-content/uploads/2020/4/bMFzAr.jpg */
	#Screen {
		background-image: url("/img/blackwallpaper.jpeg");
		background-size: cover;
	}

	@font-face {
		font-family: sevensegment;
		src: url('/fonts/7segment.woff');
	}

	/* Farben und Größe etc. für Stopuhr hier ändern: 
	Vordefinierte Farben z.B hier https://www.w3schools.com/colors/colors_names.asp */

	#Stopwatch {
		font-family: sevensegment, sans-serif;
	color: red;
	text-align: center;
	font-size: 20vw;
	}
		
</style>

