<template>
	<div id="doubleBikeContainer_Alexa" @click="nextStep">
		<div id="startscreen" v-if="view === 'startScreen' ">

			<div id="gespendeteBaeume">
				{{ totalBaumCounter }}
			</div>
			
			<!-- <div id=fieldDiv>
				<div 
						@keydown="clearLocalStorage()"
						id="leistungInWS" class="anzeige">
					{{totalWsCount.toLocaleString("de-de", {maximumFractionDigits: 0})}} Ws
				</div>
				<div id="erreichteGewinnstufe" class="anzeige">
					Stufe {{gewinnStufe.nr}} / {{gewinnStufe.euro}} €
				</div>
				<div id="nextStufe" class="anzeige">
					{{missingWsForNext.toLocaleString("de-de", {maximumFractionDigits: 0})}} Ws
				</div>
			</div> -->
		
			<div v-if="!!debug">
				<button @click="setArduino('bike')">set Bike</button>
				<button @click="setArduino('test')">set Test</button>
			</div>
		</div>

		<!-- <div id="bgKonfetti" class="BG" v-if="view === 'end'">
		</div> -->


		<div id="bgCountDown" class="BG" v-if="view === 'countdown'">
			<CountDown  key="coutdownCounter" :startCounter="counterCountDown"
				@switchView="nextStep">
			</CountDown>
		</div>

		<div id="bgPlaying" class="BG" v-else-if="view === 'playing'">
			<CountDown key="playingCounter" class="transparentBackground" :startCounter="counterRunning"
				@switchView="nextStep">
			</CountDown>
		</div>
		<div id="bgKonfetti" class="BG" v-else-if="view === 'end'">
			<!-- <CountDown key="endCounter" class="transparentBackground" :startCounter="0"
				>
			</CountDown> -->
		</div>

		<div id="leistungsradContainer"
			v-if="view != 'startScreen'">
			<LeistungsRad 
				id="links"
				class="bikeView"
				:sensorPinNr="sensorPin1"
				 :seite="'links'"
				 ref="b1"
				 key="left">
			</LeistungsRad> 
			<LeistungsRad 
				id="rechts"
				:sensorPinNr="sensorPin2"
				class="bikeView"
				:seite="'rechts'"
				ref="b2"
				key="right">
			</LeistungsRad>
		</div>

	</div>

</template>

<script>
import {mapGetters} from "vuex";
import { mapActions } from "vuex";
import CountDown from '@/components/doublebike/Countdown.vue'; // @ is an alias to /src
import LeistungsRad from '@/components/doublebike/LeistungsRadBaum.vue';

export default {
  name: 'DoubleBikeAlexa',
  
  components: {
    CountDown, LeistungsRad, //LevelBaum
  },

  data() { return {
	view: 'startScreen', // start countdown playing end

	counterCountDown: 3000,
	counterRunning: 20000,
	counterBackToStart: 5000,

	localStorageName: 'alexaData',
	//lsData: {},
	mode: "startScreen",
	threasholdToStart: 100,
	sensorPin1: 0,
	sensorPin2: 1,
	debug: false,

	totalBaumCounter: 0,
	interval: null,
	timeOut: null,

	maxBaum: 1200,

  }
},

  created () {
	  let localStorageData = this.loadDataFromLocalStorage()
	  console.log(this.$options.name + " -> created -> localStorageData:  " + localStorageData)
	  this.totalBaumCounter = localStorageData.baumCount
  },

  mounted () {
	  this.interval = setInterval(this.getArduinoDirect, 250); 
  },

  updated () {
	  console.log(this.$options.name + ": updated -> mode: " + this.mode + " / view: " + this.view)
  },

  watch: {
    arduinoData: function (_neu, _alt) {
		// autostart game by energy threashold 
	  if (this.mode == "startScreen") {
		console.log(this.$options.name + " -> watch -> mode:  " + this.mode)

		if (_neu.analogIn[this.sensorPin1] > this.threasholdToStart || _neu.analogIn[this.sensorPin2] > this.threasholdToStart) {
			console.log(this.$options.name + " -> watch -> !!! AUTOSTART TRIGGERD !!! :  ")
			this.nextStep();
		}
	  }
    }
  },

	computed:{
		...mapGetters([
		'arduinoData',
	]),
	
  },

  beforeDestroy: function(){
  },

 methods: {
    ...mapActions([
		'getArduinoDirect',
		'setArduinoIp',
	]),
	
	setArduino: function (_type) {
		console.log(" -> setArduino -> " + _type)
		if (_type === "bike") {
			this.sensorPin1 =  1
			this.sensorPin2 =  0
			this.setArduinoIp('10.116.1.156')
			this.counterRunning = 25000
		} else {
			this.sensorPin1 =  3
			this.sensorPin2 =  4
			this.setArduinoIp('10.116.1.155')
			this.counterRunning = 100000
			//this.counterBackToStart = 100000
		}
	},

	addBaum: function() {
		console.log(this.$options.name + " -> addBaum - " + this.totalBaumCounter)
		if (this.totalBaumCounter >= this.maxBaum ) {
			console.log(this.$options.name + " -> addBaum - !! Maximum Erreicht !! - So Exit  ")
			return
		}
		this.totalBaumCounter = this.totalBaumCounter + 1

		this.saveDataToLocalStorage()		//Und der funktion zum lokalen speichern �bergeben
	},

	/** startCountDown: function() {				//Funktion das Countdown-Component einzublenden
		console.log(this.$options.name + " -> startCountDown ")
		this.switchView('countdown')			//view auf "countdown setzten um die v-if Bedigung des Countdown-Components zu erf�llen
	},

	startRun: function() {

	}, */

	NOstopRun: function() {						//playing-Mode stoppen
		console.log(this.$options.name + " -> stopRun ")
		// stop countdowns .. 
		// start Winner Konfetti 
		let E1 = this.$refs.b1.myTotalPower 	//totalPower (punkte) aus den Components holen
		let E2 = this.$refs.b2.myTotalPower

		console.log(this.$options.name + " -> stopRun -> E1 " + E1 + " E2" + E2 )

		// if (E1 > E2 ) {							//Checken wer Gewonnen hat und dementsprechend Konfetti starten
		// 	console.log(this.$options.name + " -> stopRun -> Player 1 win ")
		// 	this.$refs.b1.startConfetti()
		// } else {
		// 	console.log(this.$options.name + " -> stopRun -> Player 2 win ")
		// 	this.$refs.b2.startConfetti()
		// }
		
		// add E to total 
		let addToWS = E1 + E2					//Punkte aufaddieren und auf die Gesamtpunkte/Energiemenge aufaddieren
		this.addToTotalWS(addToWS)
	},
	
	NOaddToTotalWS: function(_value) {			//Funktion zum aufaddieren der Gesamtunkte/Gesmatenergiemenge
		console.log(this.$options.name + " -> addToTotalWS : " + _value )
		this.totalWsCount = this.totalWsCount + _value	
		// store to local
		let data = {							//Datenarray(wie hei�t das? Einfach JsonArray?) anlegen...
			totalE: this.totalWsCount,		
			stufe: this.gewinnStufe
		}
		this.saveDataToLocalStorage(data)		//Und der funktion zum lokalen speichern �bergeben
	},
    
    /** backToStartScreen: function(){								//Funktion um zum startscreen zur�ckzukehren
		console.log(this.$options.name + " -> backToStart ")
		this.switchView("startScreen")
    },*/

/** 
	 * - startScreen
	 * - countdown
	 * - playing
	 * - end
	 */
	nextStep: function(){
		//clearInterval(this.interval)
		//clearTimeout(this.timeOut)
		console.log(this.$options.name + " -> nextStep  -> From: " + this.mode)
		if (this.mode == 'startScreen') {
			this.switchView('countdown')
		}
		else if (this.mode == 'countdown') {
			this.switchView('playing')
		}
		else if (this.mode == 'playing') {
			// check for inc. Bäume : 
			// player 1
			if (!!this.$refs.b1.hasReachedGoal()) {
				console.log(this.$options.name + " -> nextStep -> END  : Player 1 add 1 Baum " )
				this.addBaum()
				this.$refs.b1.goalReached = false
			}
			// player 2
			if (!!this.$refs.b2.hasReachedGoal()) {
				console.log(this.$options.name + " -> nextStep -> END  : Player 2 add 1 Baum " )
				this.addBaum()
				this.$refs.b2.goalReached = false
			}

			this.switchView('end')
		}
		else if (this.mode == 'end') {
			this.switchView('startScreen')
		}
		console.log(this.$options.name + " nextStep END  -> Now: " + this.mode)
	},

    switchView: function(mode) {								//Funktion f�r den Viewwechsel/Moduswechsel
      console.log(this.$options.name + " -> switchView -> To: " + mode)

      if(mode == 'startScreen'){
		  clearInterval(this.interval);
		  this.interval = setInterval(this.getArduinoDirect, 250); 
		  
	  }
      if(mode == 'countdown'){
	  }

      if(mode == 'playing'){
		// this.startRun()
	  }
      if(mode == 'end'){
		  clearInterval(this.interval);
//		  this.stopRun()
		 this.timeOut =  setTimeout(this.nextStep, this.counterBackToStart);
			
	  }
	  if (this.$refs.b1 != undefined) {
		this.$refs.b1.setMode(mode)
		this.$refs.b2.setMode(mode)
	  }  
		this.view = mode;
		this.mode = mode
		console.log(this.$options.name + " -> switchView END -> To: " + mode)
	},
	
	loadDataFromLocalStorage: function () {							//Funktion zum Laden des Akutuellen Werts und der Gewinnstufen
		let tempvar =  window.localStorage.getItem(this.localStorageName)
      console.log("X -> loadDataFromLocalStorage : -> ", tempvar)
      if (tempvar) {
        return JSON.parse(tempvar)
      } else {
        //get the connections from your API
        //once you have the data, display it and also save it  
        console.log("X -> loadPageHistoryFromLocalStorage : -> init item: with ??  ")
          // localStorage.setItem('userSet', state.userSet)
		window.localStorage.removeItem(this.localStorageName)
		let _data = {
			baumCount: 0,
		}
        window.localStorage.setItem(this.localStorageName, JSON.stringify(_data))
      }
	},

	saveDataToLocalStorage: function () {						//Funktion zum Speichern des Akutuellen Werts und der Gewinnstufen
		let _data = {baumCount: this.totalBaumCounter}
		console.log('X -> saveDataToLocalStorage ->  ', _data)
    	window.localStorage.setItem(this.localStorageName, JSON.stringify(_data))
	},

   },

}
</script>

<style scoped lang="scss">


#doubleBikeContainer_Alexa {
//		font-size: 13em;
		font-family: Possling;
			color: #000548;

}


#startscreen {
	background-image: url("/img/AlexaBike/Start_Holz_V5.png");
	background-repeat: no-repeat;
	background-size: contain;
	width: 100vw;
	height: 100vh; 
	h1 {
		margin : 0;
		padding: 0;
	}
}

#gespendeteBaeume {
	display: flex;
	flex-direction: column; 
	position: relative;
	height: 14vh;
	width: 31vh;
	left: 41vw;
	top: 54vh;
	// background-color: #00834D;
	border-radius: 9%;
	color: white;
	justify-content: center;
	align-items: center;
	font-family: Possling;
		color: #ffffff;
//		color: #000548;

		//font-size: 6.25rem;
		 font-size: 9vh;
	
}

#fieldDiv {
	display: flex;
	flex-direction: column; 
	position: relative;
	justify-content: space-between;
	height: 29.5vh;
	left: 34.5vw;
	top: 40.8vh;
}

.anzeige {
	border-radius: 1em;
	width: 17vw;
	height: 4vw;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 3.3vh;
	font-family: Johanniter;
	color: #000548;
}

#leistungsradContainer {
	display: flex;
	width: 100vw;
	justify-content: space-around;
}


.bikeView {
	display: flex;
	width: 50vw;
	height: 100vh;
	justify-content: center;
	align-items: center;
	position: relative;
}

#bgCountDown {
	background-image: url("/img/AlexaBike/Start_Holz_V4_CD.png");
}

#bgPlaying {
	background-image: url("/img/AlexaBike/Start_Holz_V4_Spiel.png");
}

#bgKonfetti {
	// background-image: url("/img/AlexaBike/Start_Holz_V4_endR.png");
	background-image: url("/img/AlexaBike/Split.png");
}

.BG {
	position: absolute;
	width: 100vw;
	height: 100vh;
	background-repeat: no-repeat;
	background-size: contain;
}
  
.transparentBackground{
    background-color: rgba($color: #000000, $alpha: 0.0);
}

</style>
