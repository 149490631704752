<template>
  <div id="HsAdminComponent" class="highscore">
		
    <h1> Highscore Admin </h1>

<div id="HsAdminContainer" v-if="eventList.length > 0">
		<div class="admin-row" id="admin-hs" v-for="(item, index) in eventList" :key="index">
			<div class="button"  v-on:click="(shwoClearAllPopup = true)">clear Highscore {{item.name}}</div>
			<div class="button"  v-on:click="openHsList(item)">delete one {{item.name}}</div>
			
			<div id="confirmClearAllPopup" class="admin-popup" v-if="shwoClearAllPopup">
				<h2> Komplette Highscore löschen ! </h2>
				<div class="button"  v-on:click="deleteScores(item)">JA</div>
				<div class="button"  v-on:click="deleteScoresAndMakeNew(item)">JA - mit Namen</div>
				<div class="button"  v-on:click="(shwoClearAllPopup = false)">NEIN</div>
			</div>
			
			<div id="deleteOneContainer" class="admin-popup" v-if="shwoDeleteOneContainer">
				<h2> Delete One for {{item.name}} </h2>
				<div v-for="(hsListItem, index) in hsList" class="button admin-hs-row" :key="index">
						<span v-on:click="deleteHsById(item, index)"> {{(index + 1)}} - {{hsListItem.playerName}} </span>
				</div>
			</div>
		</div>

  </div>
  </div>
</template>



<script>
  import {mapGetters} from "vuex";
  import { mapActions } from "vuex";

export default {
  name: 'HsAdmin',

  components: {
  },

   data () {
    return {
      shwoDeleteOneContainer: false,
      showAdminBar: true,
      shwoClearAllPopup: false,
      hsList: [],
       showEditWin : false,
            new_Event_name : "",
            new_Event_initscore : 0, 
             newEventName:"",
             showMore: false,
             currentHS: [],
             showHsWin:   false,
             nrlist: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]
    }
  },

  computed:{
    ...mapGetters([
      'highscores',
      'eventList', 
      'baseUrlHs',
    ]),
  },

   created() {
     //this.getHighScoreListByEvent(this.eventName)
    // this.getEvents();
  },

  mounted() {
  },

  methods: {

    ...mapActions([
      'getHighScoreListByEvent',
    ]),

    	adminButton: function(buttonName) {
				console.log("admin button pressed", buttonName);
				switch (buttonName) {
					case "hideAdminBar" :
						this.showAdminBar = false;
						break;
					case "hideDOC" :
						this.shwoDeleteOneContainer = false;
						break;
				}
			},
		  openHsList: async function(_event) {
				if (this.shwoDeleteOneContainer == true) {
					this.shwoDeleteOneContainer = false;
				} else {
          await this.getHighScoreListByEvent(_event);
          this.hsList = this.highscores[_event]
							this.shwoDeleteOneContainer = true;
				}
			},

			deleteScores: function(myEvent) {
            this.$http.post(this.baseUrlHs+'resetScore',
                myEvent).then(response => {
							console.log(" score cleared : ", response); 
							this.shwoClearAllPopup = false;
          }, response => {
            // error callback
            console.log("error : ",response);
          }) 
        },
			deleteScoresAndMakeNew: function(myEvent) {
            this.$http.post(this.baseUrlHs+'makeNewScore',
                myEvent).then(response => {
							console.log(" score cleared : ", response); 
							this.shwoClearAllPopup = false;
          }, response => {
            // error callback
            console.log("error : ",response);
          }) 
        },
			sendUpdateAll: function() {
				var xhr = new XMLHttpRequest()
				xhr.open('GET', this.baseUrlHs + "updateAll");
				xhr.onload = function () {
					console.log("updateAll : callback ", xhr.responseText);
				}
				xhr.send();
			},
			deleteHsById: function(_event, _nr){
				let _data = {'name': _event.name, 'id': _nr};
				console.log("deleteHsById -> data : ", _data);
				console.log("deleteHsById -> ", _event);
				this.$http.post(this.baseUrlHs+'deletescorebyid', _data).then(response => {
					console.log(response);
					this.shwoDeleteOneContainer = false;
					this.sendUpdateAll();
				}, response => {
					// error callback
					console.log("error : ",response);
				}) 
			},
      
			toggleAdminBar: function() {
				this.showAdminBar = !this.showAdminBar;
      },
      

       editWin : function(_event) {
         this.showEditWin = true; 
        },
        saveEvent : function(_event) {
        },
        clearEvent  : function(_event) {
            this.showEditWin = false; 
        },

         getSettings: function(myEvent) {
            this.$http.post(this.baseUrlHs+'getSettings',
                myEvent).then(response => {
                    console.log(response);
                    myEvent.settings = response.body; 
                    this.$forceUpdate();
              console.log(" get Settings :  : "); 
          }, response => {
            // error callback
            console.log("error : ",response);
          }) 
        },
         saveSettings: function(_event) {
            this.$http.post(this.baseUrlHs+'saveSettings',
                _event).then(response => {
                    console.log(response);
              console.log(" save Settings done: for ", _event); 
          }, response => {
            // error callback
            console.log("error : ",response);
          }) 
        },
         deleteHsId: function(_item, _nr){
           _nr = _nr - 1;
           let _data = {"name": _item.name, id: _nr};
            this.$http.post(this.baseUrlHs+'deletescorebyid',
                _data).then(response => {
                    console.log(response);
              console.log(" deleteHsId ", _item); 
          }, response => {
            // error callback
            console.log("error : ",response);
          }) 
        },
        
         updateEvent: function(myEvent) {
            this.$http.post(this.baseUrlHs+'updateEvent',
                myEvent).then(response => {
                    console.log(response);
              console.log(" update Event "); 
          }, response => {
            // error callback
            console.log("error : ",response);
          }) 
        },
        newEvent: function () {
            if (this.newEventName == "") {
                return ; 
            }
            let res = {"name": this.newEventName};
            this.$http.post(this.baseUrlHs+'newEventByName',
                res).then(response => {
                    console.log(response);
              console.log("new Event created  : "+this.newEventName); 
               this.newEventName = ""; 
            //   this.getEvents(this);

          }, response => {
            // error callback
            console.log("error : ",response);
          }) 
        },

        showHS: function(myEvent) {
            this.$http.post(this.baseUrlHs+'getHighScore',
                myEvent).then(response => {
                  console.log("....", response);
                  // var res =  JSON.parse(response.body);
                  var res = response.body.response;
            console.log(res);
            this.currentHS = res; // json_encode(res); 
          }, response => {
            // error callback
            console.log("error : ",response);
          }) 
        },
        toggleActive: function(myEvent) {
          console.log("try to toggleActive : ",myEvent);
            this.$http.post(this.baseUrlHs+'toggleActive',
                myEvent).then(response => {
              console.log(" toggle active : ", response); 
          }, response => {
            // error callback
            console.log("error : ",response);
          }) 
      
        }
       


  },

}

</script>

<style lang="scss" >

</style>
