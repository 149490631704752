import { render, staticRenderFns } from "./Countdown.vue?vue&type=template&id=42282221&scoped=true&"
import script from "./Countdown.vue?vue&type=script&lang=js&"
export * from "./Countdown.vue?vue&type=script&lang=js&"
import style0 from "./Countdown.vue?vue&type=style&index=0&id=42282221&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42282221",
  null
  
)

export default component.exports