<template>
  <div id="NetRelais" class="ttcontainer" >
    <h2
    @click="showDevice = !showDevice">
      ShellyPlug 
    </h2>
    <div v-if="showDevice === true">
      <div v-for="(device, index) in myDevices" :key="index" >
        <div class="twoRows">
          <div class="ttRow">
            IP {{device.deviceType}}: 
            {{device.deviceIP}}

            <Ping :myDevice="device" />

            <div class="button"
                @click="powerOn(device)">
              an
            </div>
            <div class="button"
                @click="powerOff(device)" >
              aus
            </div>
          </div>

          <div class="ttRow"
            @click="device.showFrame = !device.showFrame">
            <img src="img/shellyPlug.png" class="deviceImg" />
          </div>
        </div>

      <iframe 
        v-if="device.showFrame == true" 
        :src="device.deviceUrl" 
        class="deviceIFrame" />
    </div>
  </div>
</div>
</template>

<script>
  import {mapGetters} from "vuex";
  import { mapActions } from "vuex";
  import axios from 'axios';  

  import Ping from '@/components/devcomps/Ping.vue';

export default {
  name: 'WebRelais',
  components: {
    Ping,
  },

   data() {
    return {
      showDevice: false,
      myDevices: [
        {
          deviceName: 'Shelly 1 ',
          deviceType: 'ShellyPlug',
          deviceIP: "10.116.1.201",
          deviceUrl: 'http://10.116.1.201',
          deviceSubnetMask: '255.255.0.0',
          connectedDevice: 'WLan - tentable (VLAN)',
          deviceResponse: 'no response', 
          isReachable: false,
          deviceError: '',
          RelaisIsPowerOn: false,
          showFrame: false,
          showDevice: true,
      },
        {
          deviceName: 'Shelly 2 ',
          deviceType: 'ShellyPlug',
          deviceIP: "10.116.1.202",
          deviceSubnetMask: '255.255.0.0',
          deviceUrl: 'http://10.116.1.202',
          connectedDevice: 'WLan - Alcatel - tentable SteuerBox',
          deviceResponse: 'no response',
          deviceError: '',
          RelaisIsPowerOn: false,
          showFrame: false,
          showDevice: true,
          isReachable: false,
        },
        {
          deviceName: 'Shelly 3',
          deviceType: 'ShellyPlug',
          deviceIP: "10.116.1.203",
          deviceSubnetMask: '255.255.0.0',
          deviceUrl: 'http://10.116.1.203',
          connectedDevice: 'WLan - Alcatel - tentable SteuerBox',
          deviceResponse: 'no response',
          deviceError: '',
          RelaisIsPowerOn: false,
          showFrame: false,
          showDevice: true,
          isReachable: false,
        },
      ],
    }
  },

computed: {
    ...mapGetters([
    ]),
  },

  // mounted() {
  //   this.loopGetArduinoData()
  // },

  methods: {
    
    ...mapActions([
    ]),


    powerOff: function (_device) {
      if (_device.deviceType != 'ShellyPlug') {
         return
       }
       _device.relaisIsPowerOn = false;
       let url = 'http://' + _device.deviceIP + '/relay/0?turn=off'

      fetch(url)
      //  .then(response => this.setWebRelaisResponse(_dev, response))
      //  .catch(function(error) {
      //     console.log('error', error)
      //     _dev.deviceError = error
      // }.bind(this));
    } ,

    powerOn: async function (_device) {
      console.log(this.$options.name + " -> powerOn() ", _device)
       if (_device.deviceType != 'ShellyPlug') {
         return
       }
      let url = 'http://' + _device.deviceIP + '/relay/0?turn=on'

      let response = await axios.get(url, this.requestOptions)    
       .then(response => _device.RelaisIsPowerOn = true)
       .catch(function(error) {
          console.log('error', error)
          _device.deviceError = error
        }.bind(this)
      );
    },


  },

}

</script>

<style scoped>

  .deviceIFrame {
    width: 40vw;
    height: 50vh;
  }

  /* body {
    overflow: auto;
  } */

  /* #NetRelais {
    
  } */


</style>
