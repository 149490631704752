<template>
  <div class="pageviewContainer">
    <div class="numberContainer" :style="setFontSize()" >
				<a id="clicks">0</a>
			</div>
    </div>
</template>



<script >
  import {mapGetters} from "vuex";
  import {mapActions } from "vuex";

export default {
  name: 'KickCounterView',
  components: {
  },
  props: ['counterConfig'],

  data() { return {
      entprellung: 100,
      timer: Date.now(),
      // digits: 6,
      clicks: 0,
      resetValue: "0"
    }
  },

  created () {
    
  },            

  mounted () {
    this.clicks = this.loadDataString()
		this.showCounter(this.clicks);
		console.log(this.$options.name + 'created finish-> ', this.clicks);
    this.initKeyHandler();
  },

  methods: {
    initKeyHandler: function () {
      document.addEventListener("keydown", event => {
        // console.log("keydown: "+keyCode, event);
        if (event.isComposing || event.keyCode === 229) {
          return;
        }
        if ( event.keyCode === 48) {
          this.clicks = -1;
        }
        if ( event.keyCode === 49) {
          //clicks = clicks + 1;
        }
        if ( event.keyCode === 50) {
          this.clicks = this.clicks + 9;
        }                
        if ( event.keyCode === 51) {
          this.clicks = this.clicks + 99;
        }
        if ( event.keyCode === 52) {
          this.clicks = this.clicks + 999;
        }
        if ( event.keyCode === 53) {
          this.clicks = this.clicks + 9999;
        }

        console.log(this.$options.name + '  keyEvent: ' + event.keyCode);
        
        // do something
        // entprellung 
        if (this.timer + this.entprellung < Date.now()) {
          this.timer = Date.now();
          this.onClick();
        } 
      })
    },

    setFontSize: function () {
      return { 'font-size' : this.counterConfig.fontSize + 'vw'};
    },
    
    makeString: function(_clicks) {
      _clicks = _clicks * this.counterConfig.increments
      let _string = _clicks.toFixed(this.counterConfig.nachKommaStellen)
      // leading zeros
      if (this.counterConfig.nachKommaStellen == 0) {
        while (_string.length < this.counterConfig.digits) {
          _string = '0' + _string;
        }
      } else {
        while (_string.length < this.counterConfig.digits 
          + this.counterConfig.nachKommaStellen + 1) {
          _string = '0' + _string;
        }
      }
      _string = _string + this.counterConfig.suffix
      return _string
    },
    
    onClick:  function() {
      this.clicks = this.clicks + 1;

      // show in HTML
      this.showCounter(this.clicks);
      this.saveCounter(this.clicks); 
    },

    showCounter: function (_clicks) {
      let _string = this.makeString(_clicks)
      console.log("showCounter -> " + _string)
      // // leading zeros 
      // _string = '' + _string;
      // while (_string.length < this.counterConfig.digits) {
      //   _string = '0' + _string;
      // }
      document.getElementById("clicks").innerHTML = _string;
    },

    saveCounter: function (_text) {
      localStorage.setItem("counts", _text);
    },

    resetLocalStorage: function (_resetValue) {
      console.log("resetLocalStorage .> set ", _resetValue);
      this.saveCounter(_resetValue);
      this.showCounter(_resetValue)
      this.clicks = Number(_resetValue);
    },

    loadDataString: function() {
      let clicks  = Number(localStorage.getItem("counts")); 
      console.log(this.$options.name + 'doc ready -> load from local Storag', clicks);
      return clicks;
    },
    

  } // end Methods 
}



</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@font-face {
	font-family: Segment;
	src: url('/fonts/7segment.ttf')
	format("truetype");
}

html {
	cursor: none;
}

div {
	background-color: black;
	font-family: Segment, Hevetica, Arial, sans-serif;
	cursor: none;
}

.pageviewContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  padding: 0; 
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
}
/*position: relative;
	margin: 24vh auto 0 auto;
  width: 79vw;*/

.numberContainer {
	color: red;
	cursor: none;
  font-size: 15vw;
	user-select: none;
	letter-spacing: 0.1em

}

</style>
