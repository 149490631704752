<template>
  <div class="pageContainer">
      <h1> PowerBike </h1>
    <div class="powerbike ">

      <div class="devices">

      <!-- <div id="NetRelais" class="device">
        <h2> WebRelais </h2>
        <div class="twoRows">
          <div class="ttRow">
            <div class="ttcontainer">
              <h3> settings </h3>
              IP der NetRelais Box : 
              {{webRelaisIp}}
            </div>
            <div class="ttcontainer">
              <h3> Manuell Channel 1</h3>
              <div class="button"
                @click="powerOn('1')"
                >
                an
              </div>
              <div class="button"
                @click="powerOff('1')"
                >
                aus
              </div>
            </div>
            <div class="ttcontainer">
              <h3> Manuell Channel 2</h3>
              <div class="button"
                @click="powerOn('2')"
                >
                an
              </div>
              <div class="button"
                @click="powerOff(2)"
                >
                aus
              </div>
            </div>
        <div> 
          threshold1An : {{threshold1An}} -- {{myPower}}  -- {{threshold1Aus}} : threshold1Aus 
        </div>


        <div class="ttcontainer">
          <h2 
           :class="isPowerOn ? 'an' : 'aus'"> Interactive </h2>
          <div class="button"
              @click="stopPowerBike()"
              v-if="isInteractiveMode"
              >
            stop
          </div>
          <div class="button"
              @click="startPowerBike()"
              v-else
              >
            start
          </div>
        </div>

        <div class="response">
          {{webRelaisResponse}}
        </div>
        <div class="error"
          v-if="webRelaisError !== ''">
          {{webRelaisError}}
        </div>
      </div>


        <div class="ttRow">
          <img src="img/webRelais.png" class="deviceImg" />
        </div>
 
      </div>
      
      </div> -->
      
      <div class="device">
        <ShellyPlug />
      </div>

      </div>
    
      <Arduino :channel="1" class="pageSection"/>
      <!-- <Arduino :channel="2" class="pageSection"/> -->
    </div>

    <div class="twoRows">
          <div class="ttRow">
            
          </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios';
import Arduino from '@/components/Arduino.vue'; // @ is an alias to /src
import ShellyPlug from '@/components/devices/ShellyPlug.vue'; // @ is an alias to /src

import {mapGetters} from "vuex";
  // import { mapActions } from "vuex";


export default {
  name: 'PowerBike',

  components: {
    Arduino,
    ShellyPlug
  },

  data() {
    return {
      devices: [
         {
          deviceName: 'webRelais1',
          deviceType: 'WebRelais',
          deviceIP: "10.116.1.198",
          deviceResponse: 'no response',
          deviceError: '',
          deviceThresholdOn: 150,
          deviceThresholdOff: 100,
          deviceIsInteractiveMode: false,
          RelaisIsPowerOn: false,
          ArduinsensorPinNr: 0,
          myPower: 0,
        }
      ],
      webRelaisIp: "10.116.1.198",
      webRelaisResponse: 'no response',
      webRelaisError: '',
      threshold1An: 150,
      threshold1Aus: 100,
      // threshold2An: 100,
      // threshold2Aus: 120,
      // powerMode: 1,
      isInteractiveMode: false,
      isPowerOn: false,
      sensorPinNr: 0,
      myPower: 0,
    }
  },

  created() {
  },

  mounted() {
  },

  computed: {
     ...mapGetters([
      'arduinoData',
    ]),
  },

  
  watch: {
    arduinoData: function (_neu, _alt) {
      this.checkForRelaisAction(_neu.analogIn)
    }
  },

  methods: {
    startPowerBike: function () {
      this.isInteractiveMode = true
    },
    stopPowerBike: function () {
      this.isInteractiveMode = false
    },

    powerOff: function (_channel) {
       this.isPowerOn = false;
       let action = "/FF0" + _channel + "00"
      fetch('http://' + this.webRelaisIp + action )
       .then(response => this.setWebRelaisResponse(response))
       .catch(function(error) {
          console.log('error', error)
          this.webRelaisError = error
      }.bind(this));
    },

     powerOn: async function (_channel) {
       let action = "/FF0" + _channel + "01"
        this.isPowerOn = true;
      let url = 'http://' + this.webRelaisIp + '/FF0' + _channel + '01'
      const requestOptions = {
         headers: {
          },
      }

      let response = await axios.get(url, requestOptions)    
       .then(response => this.setWebRelaisResponse(response))
       .catch(function(error) {
          console.log('error', error)
          this.webRelaisError = error
        }.bind(this)
      );
    },

    checkForRelaisAction: function (analogData) {
      this.myPower = analogData[this.sensorPinNr]
      if (this.isPowerOn && this.myPower < this.threshold1Aus) {
        this.powerOff()
      }
      if (!this.isPowerOn && this.myPower > this.threshold1An) {
        this.powerOn()
      }
    },

    setWebRelaisResponse: function (_response) {
      this.webRelaisResponse = _response
    }

  },
}
</script>

<style scoped>
  .response {
    background-color: lightgray;
    padding: 1vh;
  }
  .error {
    background-color: lightcoral;
    padding: 1vh;
  }

  .powerbike {
    display: flex;
    justify-content: space-around;
  }
  
  .pageSection {
    max-width: 35vw;
    padding: 1vw;
  }

  #NetRelais {
    min-width: 39vw;
  }

  .an {
    background-color: aquamarine;
  }
  .aus {
    background-color: indianred;
  }


  h2 {
    text-align: center;
    font-size: 3vh;
  }
</style>
