<template>
  <div class="">
      <button @click="clearLocalStorage()">  Reset Local Storage </button>
  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import { mapActions } from "vuex";

export default {
  name: 'ResetLocalStorage',

  computed:{
    ...mapGetters([
    ]),
    
  },

  mounted() {
  },

  methods: {
     ...mapActions([
      'clearLocalStorage',
    ]),
  }

}

</script>

<style scoped>
</style>
