<template>
    <div id="PowerLineDeviceContainer" class="ttcontainer">
      <h2
           @click="device.showDevice = !device.showDevice">
        PowerLine
      </h2>
      <div v-if="device.showDevice == true" >
        <div class="wichtigeInfos">
          Zuerst die FrontLine einschalten und warten bis der Router vollständig initialisiert ist. 
          Dann die RearLine einschalten. Nun können die Arduinos ein Netzwerk finden und anfangen zu arbeiten. 
        </div>
        <ul>
          <li v-for="(a, index) in device.connections.front" :key="index" >
            {{a.nr}} - {{a.connectedDevice}}
          </li>
        </ul>
        <ul>
          <li v-for="(a, index) in device.connections.rear" :key="index" >
            {{a.nr}} - {{a.connectedDevice}}
          </li>
        </ul>
      </div>
    </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import { mapActions } from "vuex";

export default {
  name: 'EthernetPatchBay',

   data() {
    return {
      device: {
        deviceName: 'PowerLine',
        deviceType: 'SchukoSuplie',
        showDevice: false,
        connections: {
          rear: [
            {nr: 1, connectedDevice:'USB Hub'},
            {nr: 2, connectedDevice:'Lüfter'},
            {nr: 3, connectedDevice:'PowerBikeHut'},
            {nr: 4, connectedDevice:'Switch'},
            {nr: 5, connectedDevice:'no Device'},
            {nr: 6, connectedDevice:'no Device'},
            {nr: 7, connectedDevice:'no Device'},
          ],
          front: [
            {nr: 1, connectedDevice:'no Device'},
            {nr: 2, connectedDevice:'no Device'},
            {nr: 3, connectedDevice:'no Device'},
            {nr: 4, connectedDevice:'Shelly Plug 1'},
            {nr: 5, connectedDevice:'no Device'},
            {nr: 6, connectedDevice:'Alcatel Router'},
            {nr: 7, connectedDevice:'no Device'},
          ],
        }
      }
    }
  },

computed: {
    ...mapGetters([
    ]),
  },

  // mounted() {
  //   this.loopGetArduinoData()
  // },

  methods: {
    ...mapActions([
    ]),
  },

}

</script>

<style scoped>

  .wichtigeInfos {
    color: red;  
  }

  /* .PowerLineDeviceContainer {
    background-color: lemonchiffon;
  } */

</style>
