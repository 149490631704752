<template>
  <div class="wheel_wrap">
    <!-- <h1>Easier vue-wheel</h1> -->
 <!-- https://github.com/joffreyBerrier/vue-fortune-wheel -->
    <div  v-if="showwheel">
      <Wheel 
          class="drehscheibe"
          ref="wheel"
          :gift="compgift"
          :data="wheeldata"
          @done="done"
          :imgParams="logo"
          @click="spinTheWheel"
          :animDuration="6000"
          :middleColor="middleColor"
          :strokeColor="strokeColor"
          :strokeWidth="strokeWidth"
      />
    </div>
  </div>
</template>

<script>
import Wheel from "@/components/Wheel.vue"

export default {
  name: 'GluecksRadAOK',

  components: {
    Wheel,
  },

  data() {
    return {
      showwheel: false,
      gift: 1,
      logo: {
        width: 30,
        height: 30,
        src: "img/AOK_White.png",
      },
      middleColor: '#005d3e',
      strokeColor: '#005d3e',
      strokeWidth: 0,
      wheeldata: [],
    }
  },

  created() {
    fetch('./data/customdataAOK.json')
      .then(response => response.json())
      .then(data => this.setdata(data));
  },

  mounted() {
    console.log("Hallo");
    window.addEventListener("keyup", e => {   //e => == function(e)
      console.log("keyup: " + String.fromCharCode(e.keyCode));
      this.spinTheWheel();
    });
    // this.showwheel=true;
  },

  computed: {
    compgift: function () {
      return this.gift
    }
  },

  methods: {
    setdata: function (_data) {
      this.wheeldata = _data;
      console.log(_data);
      this.gift = this.randomGift();
      this.showwheel = true;
    },

    done: function (params) {
      console.log(this.$options.name + ' oder per Hand : App (conponent)', params)
      setTimeout(() => {
        this.$router.go();
      }, 3000);

    },
    spinTheWheel: function () {
      console.log("spinTheWheel")
      this.gift = this.randomGift()
      this.$refs.wheel.spin()
    },

    randomGift: function () {
      return Math.floor(Math.random() * this.wheeldata.length) + 1
    }, 
  },
}
</script>

<style scoped>
  .wheel_wrap {
    display: block;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    background-image: url("/img/AOK_Hinten.png");
    background-size: cover;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
</style>
