<template>
    <div id="WSContainer" class="ttcontainer">
      <h2
           @click="showDevice = !showDevice">
        WarteSchlange
      </h2>
      <div v-if="showDevice == true" >
        <button @click="addToWarteSchlange('112')">
          addMeToWarteSchlange 
        </button>
        <div class="wichtigeInfos">
          Die Warteliste : 
          {{warteListe}}
        </div>
        <ul>
          <li v-for="(a, index) in warteListe" :key="index" >
           {{index}} ) -- {{a.instanceId}}  -- [<span @click="removeFromWarteListe(a.instanceId)"> x </span>]
          </li>
        </ul>
      </div>
    </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import { mapActions } from "vuex";

export default {
  name: 'WarteSchlange',

   data() {
    return {
        showDevice: false,
    }
  },

computed: {
    ...mapGetters([
      'warteListe',
    ]),
  },

  created() {
    this.getWarteListe()
  },

  // mounted() {
  //   this.loopGetArduinoData()
  // },

  methods: {
    ...mapActions([
      'getWarteListe',
      'removeFromWarteListe',
      'addToWarteSchlange',
    ]),
  },

}

</script>

<style scoped>

  .wichtigeInfos {
    color: red;  
  }

  /* .PowerLineDeviceContainer {
    background-color: lemonchiffon;
  } */

</style>
