<template>
  <div class="">
    <label> server IP </label>
        <input v-model="baseUrl"> 
        <div class="button" id="setIpButton" 
          @click="setApiUrl(baseUrl)" > 
            Set server BaseUrl
        </div>
  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import {mapActions } from "vuex";

export default {
  name: 'ServerSettings',

  data() {
    return {
      baseUrl: "",
    }
  },

  computed:{
    ...mapGetters([
      'baseApiUrl'
    ]),
  },

  mounted() {
    this.baseUrl = this.baseApiUrl
  },

  methods: {
     ...mapActions([
      'setApiUrl',
    ]),
  }

}

</script>

<style scoped>
</style>
