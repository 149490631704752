<template>
	<div 
		id="Screen"
		class="Screen"
		@click="clickTapBuzz()"
	>
		<div class="debugWin"> {{ t }} </div>
		<div class="timeBuzzHeadline"> 
			<div>
				STOPPE EXAKT 
			</div>
			<div id="timeContainer">
				{{timeToShow}}
			</div>
			<div>
				SEKUNDEN
			</div>
		</div>
		<div id="textContainer">
			<div class="" v-html="textToShow">
			</div>
		</div>
		<div id="ergebnisContainer" 
			v-html="ergebnisText"
		></div>

		<img 
			v-if="currentState === STATE_WAIT_TO_START"
			id="buzzerAnim"
			src="/timeBuzzer/Buzzer_Bewegung.gif"
		/>

		<div 
			v-else
			id="buzzerStatic"
		></div>
		
	</div>
</template>
	
	
<script>

export default {
	name: 'TimeBuzzer',
	
	props: ['timeToGo'],

	data() {
		return {

			timeToShow: 0.00,
			timeToReach: 5000, 
			textToShow: "",
			text_infoOnStart: "PRESS BUZZER <br> TO START",
			text_ergebnis_perfekt: "wow ... PERFEKT !!!",
			text_ergebnis_schlecht: "nicht ganz ... gleich nochmal ",
			TEXT_RUNNING: "DIE ZEIT LÄUFT ",
			ergebnisText: "",

			/** Länge des Countdowns in Millisekunden */
			countdowntime : 5000,

			/** Anzeigemodi: 1 = Sekunden : Hundertstel, 2 = Minuten : Sekunden : Zehntel, 3 = Minuten : Sekunden,
			else Minuten : Sekunden : Hundertstel */
			modus : 1,
			/** Nur Anfangszeit (countdowntime) */
			COUNTMODE_NOCOUNT : 0,
			/** Countdown mit Anfangszeit (countdowntime) */
			COUNTMODE_DOWN : 1,
			/** Stoppuhr mit Endzeit (countdowntime) */
			COUNTMODE_UP : 2,
			/** Stoppuhr ohne Endzeit (endless) */
			COUNTMODE_UP_ENDLESS: 3,

			countMode: 0,


			STATE_WAIT_TO_START: 0,
			STATE_RUNNING: 1,
			STATE_SHOW_RESULT: 2,

			currentState: 0,

			timerInterval: null,

			/** Soundfile hier einfügen 
			Testsounds
			Horn (testsound_horn.wav):  https://assets.mixkit.co/sfx/download/mixkit-truck-horn-720.wav
			Flute (testsound_flute.wav): https://assets.mixkit.co/sfx/download/mixkit-melodical-flute-music-notification-2310.wav
			*/
			soundfile: '/snd/testsound_flute.wav',

			/** sound auf true setzten, um Soundfile abzuspielen */
			sound : true,
			
			t : -1,

			minuten : -1,
			sekunden : -1, 
			zehntel: -1,  
			hundertstel: -1,
			restzeit : -1, 
			startzeit : -1,

			deltaT: 0,
		}
	},

	/** Count-Mode hier einstellen (s.o) */
	created () {
		console.log(this.$options.name + " -> created -> time in sec: " + this.timeToGo)
		if (!this.timeToGo) {
			// wenn keine zeit angegeben - nehme 5 sekungen ... 
			this.timeToGo = 5;
		} else {
			// remove unwant chars 
			// this.timeToGo = ( "" + this.timeToGo).replace(/^\D+/g, '');
		}
		this.countdowntime = this.timeToGo * 1000;

		this.timeToShow = this.zeroPad(Number(this.timeToGo), 2) + ":00"
	},

	mounted () {
		this.resetGame()
		this.addKeyDownListener()
	},

	methods: {

		zeroPad : (num, places) => String(num).padStart(places, '0'),

		startGAME: function() {
			this.startzeit = new Date().getTime();
			this.timerInterval = setInterval(this.countdown, 50);
		},

		/** Wird durch zweites Drücken von Leertaste oder Enter aufgerufen wenn Zeit verbleibt.
		Hält die Uhr an */
		stopGAME: function() {
			this.deltaT = Math.floor(Math.abs(this.t - this.timeToGo*1000) / 10);
			clearInterval(this.timerInterval);
		},

		resetGame: function() {
			this.currentState = this.STATE_WAIT_TO_START;
			this.t = 0;
			this.countMode = this.COUNTMODE_UP;
			this.textToShow = this.text_infoOnStart;
			this.deltaT = 0;
			this.ergebnisText = "";
		},

		/** Wird durch die Funktion timer() aufgerufen.
		Zählt die Zeit und zeigt sie an */
		countdown() {
			const aktuellezeit = new Date().getTime(); 

		/* t = Zeit in Millisekunden
		Wenn countMode cauf COUNTMODE_UP oder COUNTMODE_UP_ENDLESS steht, wird t hoch gezählt, sonst runter */	
			if (this.countMode === this.COUNTMODE_UP || this.countMode === 3) {
				this.t = aktuellezeit - this.startzeit;
			} else {
				this.t = this.countdowntime + this.startzeit - aktuellezeit;
			}	
			
				
				if (this.sound === true) {
					new Audio(this.soundfile).play();
				}
			
			if (this.t <= 0 ) {
				this.t = 0
			}

		/* Darstellung der Zeit je nach Anzeigemodus */
			this.minuten = Math.floor(this.t/60000);
			this.sekunden = Math.floor((this.t - this.minuten * 60000) / 1000);
			this.zehntel = Math.floor((this.t - (this.minuten * 60000) - (this.sekunden * 1000)) / 100);
			this.hundertstel = Math.floor((this.t - (this.minuten * 60000) - (this.sekunden * 1000) - (this.zehntel * 100)) / 10);
			if (this.minuten < 10) {
				this.minuten = "0" + this.minuten;
			}
			
			if (this.sekunden < 10) { 
				// UiUiUi ... aus int wird string ... böse ... :)
				this.sekunden = "0" + this.sekunden;
			}
			if (this.modus === 1) {
				this.restzeit = this.sekunden + ":" + this.zehntel + this.hundertstel;
			} else if (this.modus === 2) {
				this.restzeit = this.minuten + ":" + this.sekunden + ":" + this.zehntel;
			} else if (this.modus === 3) {
				this.restzeit = this.minuten + ":" + this.sekunden;	
			} else {
				this.restzeit = this.minuten + ":" + this.sekunden + ":" + this.zehntel + this.hundertstel;
			}
		},

		

		/** Eventlistener, wird beim Mounten aufgerufen 
			je nach Status von "running" werden die Funktionen timer(), stopwatch() oder resetwatch() aufgerufen */
		addKeyDownListener: function()	{
			window.addEventListener("keydown", this.clickTapBuzz);
		},

		clickTapBuzz: function(event) {
			console.log(` -> clickTapBuzz () - currentState : ` + this.currentState);
			switch (this.currentState) {
				case this.STATE_WAIT_TO_START:
					this.startGAME();
					this.textToShow = this.TEXT_RUNNING;
					this.currentState++;
					break;
				case this.STATE_RUNNING:
					this.stopGAME();
					this.textToShow = this.zeroPad((this.t / 1000).toFixed(2), 5);
					console.log(" - this.deltaT - this.deltaT" + this.deltaT)
					if (this.deltaT == 0) {
						this.ergebnisText = this.text_ergebnis_perfekt;
					} else {
						this.ergebnisText = this.text_ergebnis_schlecht;
					}
					// TODO :.. cgheck íf treffer ... 
					this.currentState++;
					break;
				case this.STATE_SHOW_RESULT:
					this.resetGame();
					this.currentState = this.STATE_WAIT_TO_START;
					break;
				default:
					console.log(`Sorry, we are out of ${expr}.`);
				}

			}
	} // end methods
}
 
</script>

<style scoped lang="scss">

	.debugWin{
		position: absolute;
		right: 0;
		top: 5vh;
		width: 10vw;
		font-size: 2vh;
		color: aliceblue;
	}

	#textContainer  {
		// position: absolute;
		z-index: 1000;
		font-size: 7vh;
		color: white;
		top: 10vh;
		padding: 5vw 10vw;
		background: black;
		border-radius: 2vw;
		background: rgba(0, 0, 0, 0.3);
		margin: 0 0 0 15vw;
	}

	#ergebnisContainer {
		font-size: 7vh;
		color: white;
		position: absolute;
		bottom: 20vh;
		margin: 0 0 0 15vw;
	}

	#buzzerAnim {
		position: absolute;
		bottom: 0;
		left: 0; 
		width: 26vw;
	}

	#buzzerStatic {
		position: absolute;
		background-image: url("/timeBuzzer/Buzzer_unten.png");
		background-size: cover;
		width: 100vw;
		height: 100vh;
	}

	.Screen {
		display: flex;
		width: 100vw;
		height: 100vh;
	/*  "flex-start" für links, "center" für mittig und "flex-end" für rechts */
		justify-content: center;
	/*  "flex-start" für oben, "center" für mittig und "flex-end" für unten */	
		align-items: center;
	}

	/* Testbild: Eigene Ressourcen einfügen!
	https://www.pcclean.io/wp-content/uploads/2020/4/bMFzAr.jpg */
	#Screen {
		background-image: url("/timeBuzzer/background.png");
		background-size: cover;
	}

	@font-face {
		font-family: sevensegment;
		src: url('http://tentable.de/fonts/7segment.woff');
	}

	/* Farben und Größe etc. für Stopuhr hier ändern: 
	Vordefinierte Farben z.B hier https://www.w3schools.com/colors/colors_names.asp */
	#Stopwatch {
		font-family: sevensegment, sans-serif;
		color: red;
		text-align: center;
		font-size: 20vw;
	}

	.timeBuzzHeadline {
		position: absolute;
		color: white;
		font-size: 7vh;
		top: 5vh;
		left: 0;
		width: 100vw;
		display: flex;
		align-content: center;
		flex-flow: nowrap;
		justify-content: center;
		flex-direction: row;
		align-items: center;
	}

	#timeContainer {
		margin: 0 1.5vw;
		padding: 1vw;
		border-radius: 0vw;
		background: rgba(0, 0, 0, 0.3);
		width: 10vw;
		color: white;
		overflow: hidden;
	}


</style>

