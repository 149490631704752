<template>
  <div class="container text">
      <h3 v-bind:style="isReachable">  Ping </h3>
  </div>
</template>

<script>
  import axios from 'axios';
  import {mapGetters} from "vuex";
  // import { mapActions } from "vuex";

export default {
  name: 'Ping',
  props: ["myDevice"],

  computed:{
    ...mapGetters([
      'eSum',
    ]),
    
    isReachable: function () {
      let bgColor = "grey"
      if (this.myDevice.isReachable === true) {
        bgColor = "green"
      } else if (this.myDevice.isReachable === false) {
        bgColor = "red"
      }
      let retval = {bgColor: bgColor}
      console.log (retval)
      return retval
    }
  },

  mounted() {
    this.pingDevice(this.myDevice)
  },

  methods: {
    pingDevice: async function (_device) {
      console.log(this.$options.name + " -> pingDevice: ", _device)
      const response = await axios.get(_device.deviceUrl);
      console.log(this.$options.name + " -> pingDevice: -> return status: " + response.status , _device)

      if (response.status === 200) {
        _device.isReachable = true
      } else {
        _device.isReachable = false
      }
    }
  }

}

</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
</style>
