<template>
  <div>
    <DigiCounter :counterConfig="counterConfig" />
  </div>
</template>

<script>
import DigiCounter from "@/components/DigiCounter.vue"

export default {
  name: 'KickCounter',

  components: {
    DigiCounter,
  },

  data() {
    return {
      counterConfig: {
        digits: 5,
        nachKommaStellen: 0,
        increments: 1,
        suffix: '',
        fontSize: 25,
      }
    }
  }, 

  created() {
  },

  mounted() {
    console.log(this.$options.name + " -> mounted");
  },

  computed: {

  },

  methods: {
 
  },
}
</script>

<style scoped>
</style>
