<template>
  <div class="pageContainer2">

  
    <img class="background" src="/img/allianzWindpark/WindPark_start.png" 
    @click="callNextStep()"/>
  
  
  </div>
</template>

<script>
export default {
  name: "AllianzStaticStart",

  emits: ['nextStep'],

  components: {},

  data() {
    return {};
  },

  created() {},

  mounted() {},

  computed: {},

  methods: {
    callNextStep: function() {
      this.$emit('nextStep');
    }
  },
};
</script>

<style lang="scss" scoped>

.pageContainer2 {
  width: 100vw;
  height: 100vh;
}

.background {
  width: 100vw;
}

/**

#cookie-message {
  display: none !important;
}
#toast-container {
  display: none !important;
}

body {
  background: url(/img/allianzWindpark/WindparkBG_FHD.png);
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
  height: 100%;
  width: 100%;
}
.dkm-main {
  flex-grow: 1;
}

.dkm-header,
.dkm-main,
.dkm-footer {
  flex-shrink: 0;
}
.h1 {
  color: #00908d;
  font-size: 86px;
  font-weight: 400;
}
.h2 {
  color: #003781;
  font-size: 60px;
  font-weight: 400 !important;
}
.h3_green {
  color: #00908d;
  font-size: 36px;
  line-height: 0.7;
  font-weight: 400;
}
.h3_blue {
  color: #003781;
  line-height: 0.7;
  font-size: 36px;
  font-weight: 400;
}

.btn-dkm {
  width: 350px;
  background-color: #00908d;
  border-radius: 6px;
  font-size: 30px;
  border: none !important;
  translate: 0% -110%;
}

.btn-dkm:hover,
.btn-dkm:focus,
.btn-dkm:active {
  background-color: #00908d !important;
}

.donotselect {
  user-select: none;
}

.az_logo_dkm {
  width: 320px;
}

.eventHeadline {
  display: none;
} */
</style>
