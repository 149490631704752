<template>
  <div class="qrImgMainFrame">
    <div class="qrInput" 
      v-if="showQRinput"
      v-on:keyup.enter="chekCode(newcode)"
    >
      <input class="csInput" v-model="newcode" placeholder="insert code "> 
      <div class="button" id="newEventButton" @click="chekCode(newcode)">go</div>
    </div>
    <div class="qrImg" 
      v-else>
      <img :src="imgSrc">
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
// import { mapActions } from "vuex";
// import MainNav from '@/components/Navi.vue'; // @ is an alias to /src

export default {
  name: 'QrImg',
  props: ["code"],

  components: {
  },

  data() {
    return {
      showQRinput: false,
      imgBaseFolder: "http://tentable.de/static/bub/",
      // imgBaseFolder: "/img/qrimg/",
      imgSrc: "",
      newcode: "",
    }
  },

  computed:{
    ...mapGetters([
    ]),
  },

  mounted() {
    this.chekCode(this.code)
  },

  methods: {

    chekCode: function (_code) {
      if (!_code) {
        this.showQRinput = true
      } else {
        this.showQRinput = false
      }
      this.imgSrc = this.imgBaseFolder + _code + ".png"
    }
  }

  


}

</script>

<style scoped>

</style>
