<template>
  <div>
    <DigiCounter :counterConfig="counterConfig" />
  </div>
</template>

<script>
import DigiCounter from "@/components/DigiCounter.vue"

export default {
  name: 'EuroCounter',

  components: {
    DigiCounter,
  },

  data() {
    return {
      counterConfig: {
		digits: 4,
		nachKommaStellen: 2,
        increments: 0.1,
		suffix: '&#8364',
		fontSize: 15,
      }
    }
  },

  created() {
  },

  mounted() {
    console.log(this.$options.name + " -> mounted");
  },

  computed: {

  },

  methods: {

  },
}
</script>

<style scoped>
</style>
