<template>
	<div id="barPowerContainer">
		<div 
			id="powerBarBack" 
			class="powerBar"
		></div>
		<div 
			id="powerBar" 
			:style="barHeight()" 
			class="powerBar"
		></div>
	</div>
</template>

<script>
  import {mapGetters} from "vuex";
  // import { mapActions } from "vuex";
 
export default {
  name: 'PowerBar',
  
  props: ["sensor"],

  components: {
  },

   data () {
    return {
		myPower: 0,
		myTotalPower: 0,
		powerNorm: 0,

		maxH: 600,
    }
  },

  computed:{
    ...mapGetters([
		"arduinoData",
		'eSum'
	]),

  },

  created() {
  },

  mounted() {
	this.NOsensor = {
		name: "arduino uno",
		controllerType: "arduino",
		type: "windpark",
		pinNr: 1,
		maxValue: 100
	}
  },

  watch: {
    /* arduinoData: function (_neu, _alt) {

		if (this.sensor.type === "windpark") {
			// wenn WindPark - dann alle Analog Inputs 
			// this.myInstantArduinoPower = _neu.analogIn[0] + _neu.analogIn[1]  + _neu.analogIn[2]  + _neu.analogIn[3]  + _neu.analogIn[4]  + _neu.analogIn[5] 
			this.myInstantArduinoPower = _neu.analogIn[0] + _neu.analogIn[1]  + _neu.analogIn[2]  + _neu.analogIn[3]  + _neu.analogIn[4]  + _neu.analogIn[5] 
		} else if  (this.sensor.type === "windrad") {
			// nur einen Input 
			
			// const threashold = 60
			let pinNr = this.sensor.sensorPinNr
			this.myInstantArduinoPower = _neu.analogIn[pinNr]
		} else {
			console.Error(" watch -> arduinoData : - SENSOR UNKNOWN  ", this.sensor )
		}
		
		this.powerNorm = this.myInstantArduinoPower / this.sensor.maxValue / 6

		console.log(" watch -> arduinoData : " + this.powerNorm , _neu)
    } */
  },

  methods: {
		barHeight() {
			let p = Math.round(this.eSum / 150)
			let h = '' + p + '%'
			console.log(" -- TotalPower barHeight : " + h)
			return { 'height' : h};
		},

	}
}

</script>

<style scoped lang="scss">

.anzeige {
	position: absolute;
	top: 80vh;
	left: 12vw;
	border-radius: 1em;
	width: 11vw;
	height: 4.5vw;
	justify-content: center;
	align-items: center;
	font-size: 4vh;
	font-family: Johanniter;
	color: #000548;
}

.rootDiv {
	position: relative;
}

.powerBar {
	position: absolute;
	width: 100%;
	bottom: 0;
	background-image: url("/img/allianzWindpark/Wiederholungsraster3.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position-y: bottom; 
	max-height: 99%;
}
#powerBarBack {
	position: absolute;
	width: 100%;
	height: 99%;
	bottom: 0;
	background-image: url("/img/allianzWindpark/Wiederholungsraster1.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position-y: bottom; 
	max-height: 99%;
}


#barPowerContainer {
	overflow: hidden;
	position: absolute;
	width: 100%;
	height: 100%; // 19vw; // 33.5vh;
//	background-image: url("/img/johann/blass.png");
//background-color: antiquewhite;
	background-repeat: no-repeat;
	background-size: contain;
	background-position-y: bottom; 
}

</style>
