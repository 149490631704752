<template>
  <div id="Screen" class="Screen">
		<button id="Stopwatch" class="Stopwatch" type="button" @click="timer()">
      <div class="buttontext" >
        {{restzeit}}
      </div>
    </button>
		<button id="Left" class="Counter" type="button" @click="countupleft()">{{left}}</button>
		<button id="Right" class="Counter" type="button" @click="countupright()">{{right}}</button>
		<button id="Reset" class="Reset" type="button" @click="resetcounter()">Reset</button>
	</div>
</template>



<script >
  // import {mapGetters} from "vuex";
  // import {mapActions } from "vuex";

export default {
  name: 'CounterStopWatch',
  // components: {
  // },

  data() { 
    return {
      /** Länge des Countdowns in Sekunden */
      countdowntime: 180,
      /** Wartezeit für Restart in Milisekunden */
      timetowaitforrestart: 5000,
      /** Setzt auch die Counters zurück wenn "true" (sonst auf false setzten) */
      alsoresetcounters: true,
      t: -1,
      restzeit: -1,
      minuten: -1,
      sekunden: -1,
      time: null,
      left: 0,
      right: 0,
      countdownActive: false,
    }
  },

  // created () {
    
  // },

  mounted () {
    this.resetwatch()
  },

  methods: {
   
    /** Counter links */
      countupleft: function() {
        this.left = this.left + 1;
      },

    /** Counter rechts */
    countupright: function() {
      this.right = this.right + 1;
    },

    /** Der Resetknopf setzt beide Counters auf 0 */
    resetcounter: function() {
      this.left = 0;
      this.right = 0;
    },

    /** Funktion wird durch Click auf Stoppuhr aufgerufen.
     * Ermöglicht den Reset bei Click auf die Uhr.
     * Ruft die Funktion countdown() alle 1000ms auf. */
    timer: function() {
      if (this.countdownActive === true) {
        this.countdownActive = false;
        this.resetwatch()
      } else {
        this.countdownActive = true
        this.countdown()
        this.time = setInterval(this.countdown, 1000);
      }
    },

    /** Wird durch die Funktion timer() aufgerufen.
     * Zählt die Sekunden runter. Bei "0:00" ertönt der Testsound.
     * Wartet (timetowaitforrestart)ms und ruft dann die Funtion resetwatch() auf. */
    countdown: function() {
      this.t = this.t -1;
      this.minuten = Math.floor(this.t/60);
      this.sekunden = this.t % 60;
      if (this.sekunden < 10) {
        this.sekunden = "0" + this.sekunden;
      }
      this.restzeit = this.minuten + ":" + this.sekunden;
      document.getElementById("Stopwatch").innerHTML = this.restzeit;
      if (this.t == 0) {
        clearInterval(this.time);
        new Audio('/snd/testsound.wav').play()
        setTimeout(this.resetwatch, this.timetowaitforrestart);
      }		
    },

    /** Setzt die Uhr und wenn "alsoresetcounters = true" auch die Counters zurück */
    resetwatch: function() {
      clearInterval(this.time);
      this.t = this.countdowntime;
      this.minuten = Math.floor(this.t/60);
      this.sekunden = this.t % 60;
      if (this.sekunden < 10) {
        this.sekunden = "0" + this.sekunden;
      }
      this.restzeit = this.minuten + ":" + this.sekunden;
      if (this.alsoresetcounters === true) {
        this.resetcounter()
      }
    },

  } // end Methods 
}

</script>

<style scoped>

  .Screen {
    width: 100vw;
    height: 100vh;
  }

  /* Testbild: Eigene Ressourcen einfügen!
  https://media.bizj.us/view/img/10314835/colorado-classic*750xx2069-1164-15-0.jpg */
  #Screen {
    background-image: url("/img/bikerace.jpg");
    background-size: cover;
  }

  /* Farben und Größe etc. für Stopuhr und Counters hier ändern: 
  Vordefinierte Farben z.B hier https://www.w3schools.com/colors/colors_names.asp */
  button {
    color: AntiqueWhite;
    font-size: 12vw;
    font-family: sans-serif;
    position: fixed;
    text-align: center;
    border: none;
  }

  .Counter {
    width: 30%;
    height: 30%;
    text-align: center;
    bottom: 5vh;
    margin: 0 2.5vw;
  }

  #Stopwatch {
      background-color: rgba(144,144,144,0.8);
    width: 40%;
    height: 40%;
    top: 0%;
    left: 30%;
  }

  #Left {
     background-color: rgba(0,144,144,0.7);
    left: 0;
  }

  #Right {
   background-color: rgba(144 ,0, 144, 0.7);
    right: 0;
  }
    
  #Reset {
    background-color: rgba(144, 144, 0, 0.7);
    color: Coral;
    font-size: 4vw;
    width: 20%;
    height: 20%;
    bottom: 10vh;
    left: 40%;
  }	


  .buttonBackground {
        background-color: rgba(144,144,144,0.5);
  }
  .buttontext {

  }

</style>
