<template>
	<div>
		loading...
	</div>
</template>

<script>
 
export default {

	name: 'AllianzStart',

	data() { 
		return {

		startScreenLink: 'https://www.nachhaltiger-als-du-denkst.de/?slug=dkm-2022-aktionstool-1&preview_token=9398c1ba0378403',
		//   startScreenLink1b: 'https://www.nachhaltiger-als-du-denkst.de/?slug=dkm2022-aktionstool-2&preview_token=d53e5314055a4a2&project_list_type=2&sort=-created_at',
		//   startScreenLink1c: 'https://www.nachhaltiger-als-du-denkst.de/?slug=dkm-2022-aktionstool-3&preview_token=4b22eb38ae1c493',

		}
	},

	mounted () {
		this.redirectToParticipate()
	},

	methods: {
		redirectToParticipate: function () {
			window.location.href = this.startScreenLink;
		}
	},
}
</script>