<template>
  <div class="pageContainer arduino" >
    <h2 
         @click="showDevice = !showDevice"
         > Arduino Data read</h2>

      <div v-if="showDevice == true" >

        <div class="button"
            @click="saveSettingsPersistent()"
            >
          save
        </div>
        <br/>

    <label>  IP: </label>
      <input v-model="myIp"> 
      <div class="button" id="setIpButton" 
          @click="setArduinoIp(myIp)" > set IP for analog input </div>
      : {{channel1.inputDeviceIP}}
      <br> 

      <div>
        <label> channel1</label>
        <input v-model="c1"> 
        <div class="button" id="setIpButton" 
          @click="setChannel1SlotNr(c1)" > set analog input pin nr.</div>
          : {{channel1.inputDeviceSlotNr}} 
        <div  
            class="sensorDebugDiv"
            :style="classStyleAnalog(c1)">
            channel1.rawData: {{channel1.rawData}}
        </div>
      </div>
      <div>
        <label> channel2</label>
        <input v-model="c2"> 
        <div class="button" id="setIpButton" 
          @click="setChannel2SlotNr(c2)" > set analog input pin nr.</div>
          : {{channel1.inputDeviceSlotNr2}} 
        <div  
            class="sensorDebugDiv"
            :style="classStyleAnalog(c2)">
           channel1.rawData: {{channel1.rawData}}
        </div>
      </div>


      <label> readIntervalTime </label>
      <input v-model="intervalTime"> 
      
      <div class="button" id="setIpButton" 
          @click="setIntervalTime(intervalTime)" > set Time [ms] </div>
      {{channel1.readIntervalTime}}


      <div class="ttcontainer">
        <div class=""
            v-if="channel1.active === true"
            >
          IS READING  
        </div>
        <div class=""
            v-else
            >
          STOPPED
        </div>
      </div>
      <div class="ttcontainer">
        <div class="button"
            @click="stopChannel1()"
            >
          stop analog input
        </div>
        <div class="button"
            @click="startChannel1()"
            >
          start analog input
        </div>
      </div>



      <div 
          id="analogDataContainer"
          class="dataContainer"
          >
          <h3> Analog In: </h3>

          <div 
              class="sensorDubugContainer">
            <div 
                v-for="(a, index) in arduinoData.analogIn" :key="index"
                >
              {{index}} <br>
              {{ a }}
              <div  
                  class="sensorDebugDiv"
                  :style="classStyleAnalog(index)">
                {{ a }}
              </div>
            </div>
          </div>

                  <div class="data">
            {{arduinoData}}
          </div>


              <h3> Digital In: </h3>  
              ==> future task
          <div 
              class="sensorDubugContainer">
            <div 
                v-for="(b, index) in arduinoData.digitalIn" :key="index"
                >
              {{index}} <br>
              {{ b }}
              <div  
                  class="sensorDebugDiv"
                  :style="classStyleDigital(b)">
                {{ b }}
              </div>
            </div>
          </div>

      </div>
    </div>


  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import { mapActions } from "vuex";

export default {
  name: 'Arduino',

  data() {
    return {
     selectedImputId: -1,
     myIp: '',
     c1: -1,
     intervalTime: -1,
     showDevice: false,
    }
  },

  mounted() {
    this.myIp = this.channel1.inputDeviceIP
    this.c1 = this.channel1.inputDeviceSlotNr
    this.intervalTime = this.channel1.readIntervalTime
  },

computed: {
    ...mapGetters([
      'arduinoData',
      'channel1',
    ]),

    // classStyle(i) {
    //     let col = Math.floor(Number(this.arduinoData.analogIn[i])   / 4);
    //     let colString = "rgb("+ col + ", " + col + ', ' + col + ')'
    //     let borderwidth = 0
    //     if (i === this.selectedImputId) {
    //       borderwidth = "3px"
    //     }
    //     return { 'background-color' : colString, 'border-size' : borderwidth};
    // },
  },

  methods: {
    
    ...mapActions([
      'startChannel1',
      'stopChannel1',
      'setArduinoIp',
      'setChannel1SlotNr',
      'saveSettingsPersistent',
      'setIntervalTime'
    ]),

    classStyleAnalog(i) {
        let col = Math.floor(Number(this.arduinoData.analogIn[i])   / 4);
        let colString = "rgb("+ col + ", " + col + ', ' + col + ')'
        let border = "solid black 1px"
        if (i === this.selectedImputId) {
          border = 'solid red 3px'
        }
        return { 'background-color' : colString, 'border' : border};
    },
    classStyleDigital(i) {
      let colString = "black"
      if (i === 1 ) {
         colString = "white"
      }
        let border = "solid black 1px"
        // if (i === this.selectedImputId) {
        //   border = 'solid red 3px'
        // }
        return { 'background-color' : colString, 'border' : border};
    },

    selectInput: function (_id) {
      console.log(this.$options.name + " -> selectInput : " + _id)
      this.selectedImputId = _id
    }

  },

}

</script>

<style scoped>

  .sensorDubugContainer {
    display: flex;
    /* border: solid black 1px; */
  }
  .sensorDebugDiv {
    padding: 1vw;
    color: blue;
    min-width: 1em;
  }

  .arduino {
    background-color: azure;
    padding: 1vw;
  }

  input {
    margin-right: 1rem;
  }
  label {
    width: 10rem;
    display: inline-table;
  }


</style>
