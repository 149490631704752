<template>
    <div id="BuzzerABC" class="ttcontainer"
    @keyup.a="stateA=false"
    @keydown.a="stateA=true"
    @keyup.b="stateB=false"
    @keydown.b="stateB=true"
    @keyup.c="stateC=false"
    @keydown.c="stateC=true"
    @keyup.d="stateD=false"
    @keydown.d="stateD=true"
          >
      <h2
            @click="device.showDevice = !device.showDevice">
        Buzzer ABCD  
      </h2>

      <div class="antwortContainer">


      <div v-if="device.showDevice == true" >
         <button class=" antwort "
          >
          <span class="button1" 
            v-if="stateA === true">
            A
          </span>
        </button>
         <button class=" antwort buttonB"> 
           <span class="button1"
           v-if="stateB === true">
            B
          </span>
         </button>
         <button class="antwort buttonC"> <span class="button1"
         v-if="stateC === true">
            C
          </span>
          </button>
         <button class="antwort buttonD"> 
           <span class="button1"
           v-if="stateD === true">
            D
          </span>
         </button>
          
    </div>



      </div>
    </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import { mapActions } from "vuex";

export default {
  name: 'EthernetPatchBay',

   data() {
    return {
      device: {
        deviceName: '4 Buzzer Box',
        deviceType: 'HID',
        showDevice: false,
      },

              
        colorRichtig : '#98c723',
        colorInit: '#666666',
        colorFalsch: '#ff0000',
        colorChoosen: '#999999',

        colorGelb: '#f9c401',
        colorBlau: '#0073bb',
        colorRot: '#ca2411',
        colorGruen: '#309e35',
        colorGrau: '#666666',

        stateA: false,
        stateB: false,
        stateC: false,
        stateD: false,
    }

  },

computed: {
    ...mapGetters([
    ]),
  },

  created() {
  },

  methods: {
    ...mapActions([
    ]),
  },

}

</script>

<style scoped lang="scss">

.buttonA {
  background-color: #f9c401;
  border-color: #2349AA;
}

 .antwortContainer {
        width: 80vw;
        display: flex;
        flex-basis: 0;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        margin: 0 10vw;
        
        button {
           // background-image: url("/img/buzzer/button_bg_schwarz.png");
            background-size: cover;
            font-size: 1.6vw;
            font-weight: 400;
            background-color: grey;
            width: 30vw;
            color: white;
            border-style: solid;
            border-width: 0.4vw;
            border-radius: 3vw;
            max-width: 30vw;
            flex-grow: 1;
            padding: 2vw 2vw;
            border-color: azure;
            position: relative;
            display: flex;
            align-items: center; /* align vertical */
            
            &:hover {
                background-color: #c7f0ea;
            }
            span {
                margin-right: 3rem;
                position: absolute;
                width: 70%;
                // display: flex;
                left: 20%;
            }
            .buzzer {
                margin: -2.5vh -1vh;
                width: 4vw;
                position: relative;
            }
        }
    }

</style>
