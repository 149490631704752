<template>
  <div class="tachoview">
    <!-- channel1 data : {{channel1.rawData}} -->
    <div class="contentContainer" >
      <video class="streetbikeVideo" id="v0" ref="myVideo" tabindex="0" autobuffer preload>
      <!-- <video id="v0" ref="myVideo" controls tabindex="0" autobuffer preload> -->
          <source type="video/webm; codecs=&quot;vp8, vorbis&quot;" src="video/GoPro_Foggy.webm">
          <!-- <source type="video/webm; codecs=&quot;vp8, vorbis&quot;" src="http://www.html5rocks.com/tutorials/video/basics/Chrome_ImF.webm"></source> -->
          <!-- <source type="video/ogg; codecs=&quot;theora, vorbis&quot;" src="http://www.html5rocks.com/tutorials/video/basics/Chrome_ImF.ogv"></source> -->
          <source type="video/mp4; codecs=&quot;avc1.42E01E, mp4a.40.2&quot;" src="video/GoPro_Foggy.mp4">
          <p>Sorry, your browser does not support the &lt;video&gt; element.</p>
      </video>
    </div>
  </div>
</template>

<script >
  import {mapGetters} from "vuex";
  import { mapActions } from "vuex";

export default {
  name: "StreetBike",
  props: ['opt'],
  components: {
  },
  data() { return {
      interval: null,
    }
  },

  // watch: {
  //   channel1: function (_neu, _alt) {
  //     this.updateFrame(_neu)
  //   }
  // },

  created () {
  },

  mounted () {
    this.startChannel1()
    this.startStreetBike()
  },


  computed:{
    ...mapGetters([
      'channel1',
    ]),
  },

  beforeDestroy: function(){
    clearInterval(this.interval);
    this.stopChannel1()
  },

  methods: {

    ...mapActions([
      'startChannel1',
      'stopChannel1',
    ]),

    startStreetBike: function() {
      this.interval = setInterval(this.updateFrames, 200)
    },

    updateFrames: function () {
      console.log(this.$options.name + " -> updateFrames")
      const multiplier = 2 / 29.97 / 200
      this.$refs.myVideo.currentTime += this.channel1.rawData * multiplier;
    }

  },
}

</script>

<style scoped>
  .streetbikeVideo {
    width: 100vw;
  }
</style>
