<template>
  <div class="wheel_wrap">
    <div class="background" >
      <img class="backgroundimg" :src="wheeldata.bg" />

      </div>
    <!-- <h1>Easier vue-wheel</h1> -->
 <!-- https://github.com/joffreyBerrier/vue-fortune-wheel -->
    <div  v-if="showwheel">
      <Wheel 
          class="drehscheibe"
          ref="wheel"
          :gift="compgift"
          :data="wheeldata.felder"
          @done="done"
          :imgParams="wheeldata.logo"
          @click="spinTheWheel"
          :animDuration="6000"
          :middleColor="wheeldata.middleColor"
          :strokeColor="wheeldata.strokeColor"
          :strokeWidth="wheeldata.strokeWidth"
      />
    </div>
  </div>
</template>

<script>
import Wheel from "@/components/Wheel.vue"

export default {
  name: 'GluecksRadBUB',

  components: {
    Wheel,
  },

  data() {
    return {
      showwheel: false,
      gift: 1,
      middleColor: '#e50d7e',
      strokeColor: '#ffffff',
      strokeWidth: 0,
      wheeldata: [],
    }
  },

  created() {
    fetch('./data/customdataBUB.json')
      .then(response => response.json())
      .then(data => this.setdata(data));
  },

  mounted() {
    console.log(this.$options.name + " -> mounted");
    window.addEventListener("keyup", e => {   //e => == function(e)
      console.log("keyup: " + String.fromCharCode(e.keyCode));
      this.spinTheWheel();
    });
  },

  computed: {
    compgift: function () {
      return this.gift
    }
  },

  methods: {
    setdata: function (_data) {
      this.wheeldata = _data;
      console.log(_data);
      this.gift = this.randomGift();
      this.showwheel = true;
    },

    done: function (params) {
      console.log(this.$options.name + ' oder per Hand : App (conponent)', params)
      setTimeout(() => {
        window.location.reload();
      }, 3000);

    },
    spinTheWheel: function () {
      console.log("spinTheWheel")
      this.gift = this.randomGift()
      this.$refs.wheel.spin()
    },

    randomGift: function () {
      return Math.floor(Math.random() * this.wheeldata.felder.length) + 1
    }, 
  },
}
</script>

<style scoped>
  .wheel_wrap {
    display: block;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    /* background-image: url("/img/T_Background.png"); */
    background-size: cover;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    z-index: 100;
  }

  .background {
    z-index: -10;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
  }
</style>
