<template>
  <div class="pageContainer">
     <MainNav /> 
     <ClearLS />
     <ServerSettings />
     
    <div class="deviceContainer">
      <div class="twoRows">
        <div class="ttRow">
          <PatchBay class="c1" />
          <PowerLine class="c2" />
          <RouterAlcatel class="c3" />
          <ShellyPlug class="c4" />
          <WebRelais class="c5" />
          <BuzzerABC class="c6" />
        </div>
        <div class="ttRow">
          <Arduino class="pageSection"/>
          <WarteListe></WarteListe>
          <HsAdmin></HsAdmin>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Arduino from '@/components/Arduino.vue'; // @ is an alias to /src
import PatchBay from '@/components/devices/EthernetPatchBay.vue'; // @ is an alias to /src
import RouterAlcatel from '@/components/devices/RouterAlcatel.vue'; // @ is an alias to /src
import ShellyPlug from '@/components/devices/ShellyPlug.vue'; // @ is an alias to /src
import WebRelais from '@/components/devices/WebRelais.vue'; // @ is an alias to /src
import PowerLine from '@/components/devices/PowerLine.vue';
import BuzzerABC from '@/components/devices/BuzzerABCtest.vue';
import WarteListe from '@/components/devcomps/WarteSchlange.vue';

import HsAdmin from '@/components/highscore/HsAdmin.vue';

import MainNav from '@/components/Navi.vue'; // @ is an alias to /src
import ClearLS from '@/components/devcomps/ResetLocalStorage.vue'; // @ is an alias to /src
import ServerSettings from '@/components/devcomps/ServerSettings.vue'; // @ is an alias to /src

import {mapGetters} from "vuex";
  // import { mapActions } from "vuex";


export default {
  name: 'Settings',

  components: {
    Arduino,
    BuzzerABC,
    MainNav,
    PatchBay,
    PowerLine,
    RouterAlcatel,
    ShellyPlug,
    WebRelais,
    ClearLS,
    WarteListe,
    HsAdmin,
    ServerSettings,
  },

  data() {
    return {
    }
  },

  created() {
  },

  mounted() {
  },

  computed: {
     ...mapGetters([
      'arduinoData',
    ]),
  },

  
  // watch: {
  //   arduinoData: function (_neu, _alt) {
  //     this.checkForRelaisAction(_neu.analogIn)
  //   }
  // },

  methods: {
  },
}
</script>

<style>
 body {
   overflow-y: auto;
 }
 
 .deviceContainer {
   width: 100vw;
   height: 100vh;
   /* overflow: scroll; */
 }

  .c1 {
    background-color: lemonchiffon;
  }
  .c2 {
    background-color: lavender;
  }
  .c3 {
    background-color: silver;
  }
  .c4 {
    background-color: azure;
  }
  .c5 {
    background-color: lavenderblush;
  }
  .c6 {
    background-color: #A2AfAa;
  }


</style>
