<template>
  <div id="LuckyEditContainer" class="ttPage">
      <Version id="versionContainer"></Version>

    <div class="header">
      <h1
        @click="showDevice = !showDevice">
        LuckyEdit - {{ versionEditor }}
      </h1>
      <div id="Datum">
        Datum: {{this.datum}}
      </div>
      <div id="VersionDataStructure">
        Data Structure Version: {{this.versionDataStructure}}
      </div>

      <div class="flowRow">
        <FormulateInput
          v-model="wheelData.projectName"
          :options="projectFileNames"
          type="select"
          placeholder="load"
          label="Load Project"
        />
        <FormulateInput
          type="file"
          name="file"
          label="Select an image to upload to: /uploads/deinBildname.xyz"
          :uploader="uploadFile"
        />
        <div class="button" id="setIpButton" 
          @click="showJson = !showJson" >
            <img src="/img/icons/arrow-down-thin.svg" />
            showJson
            <img src="/img/icons/arrow-down-thin.svg" />
        </div>
      </div>

<!-- ==================== Start Workflow Row ======================== -->
      <hr>
      <div class="flowRow">
        <div> 
          select Project
          <img src="/img/icons/arrow-up-50.svg" style="width:24px"/>
        </div>
        <div>  ==>  </div>

        <div class="button" id="setIpButton" 
          @click="loadLuckyFromServer(wheelData.projectName)" >
            load 
        </div>
        <div>  ==>  </div>

        <div> 
          edit Settings
          <img src="/img/icons/arrow-down-thin.svg" />
        </div>
        <div>  ==>  </div>

        <div class="button" id="setIpButton" 
          @click="saveLuckyToLocalStorage(wheelData)" >
            save local 
        </div>

        <div>  ==>  </div>

        <a :href="getVorschauUrl()" target="_luckyW">
          <div class="button" id="setIpButton"  >
              Vorschau 
          </div>
        </a>

        <div>  ==>  </div>

        <button class="button" id="setIpButton" 
          @click="saveLuckyToServer(wheelData)" >
            save on Server
        </button>

         <div>  ==>  </div>

        <a :href="productiveUrl + wheeldata.projectName " target="_prodView">
          <div class="button" id="setIpButton"  >
              ProdView
          </div>
        </a>

      </div>
      <hr>
<!-- ==================== END Workflow Row ======================== -->
    </div>

    <div v-if="showDevice == true" class="editContainer" >
      <FormulateForm
        class="login-form"
        v-model="wheelData"
      >

<!-- ==================== BEGIN - Hidden Fields =========================== -->
        <FormulateInput
          type="hidden"
          name="datum"
          :value="datum"
        />

        <FormulateInput
          type="hidden"
          name="versionEditor"
          :value="versionEditor"
        />

        <FormulateInput
          type="hidden"
          name="versionDataStructure"
          :value="versionDataStructure"
        />

  <!-- ==================== Projekt Name ======================== --> 
        <div class="group">
          <h2> Projekt Name </h2>
          <div class="double-wide">
            <FormulateInput
              type="text"
              name="projectName"
              placeholder="Ringcenter"
            />
          </div>  
        </div>

  <!-- ==================== Screen Ausrichtung ======================== -->     
        <div class="group ">
          <h2> Screen Ausrichtung </h2>
          <FormulateInput
            type="radio"
            name="ausrichtung"
            :options="{landscape: 'Landscape', portrait: 'Portrait'}"
            value="portrait"
          />
        </div>

  <!-- ==================== Position/Size Wheel ======================== -->
        <div class="group">

          <h2> Position/Size Wheel</h2>

          <div class="double-wide ">
            <FormulateInput
              type="number"
              label="Wheel PosX"
              name="posX"
              value="0"
              placeholder="-50"
            />
            <FormulateInput
              type="number"
              label="Wheel PosY"
              name="posY"
              value="0"
              placeholder="20"
            />
            <FormulateInput
              type="number"
              label="Wheel Size"
              name="size"
              value="96"
              placeholder="50"
            />
            <FormulateInput
              type="number"
              label="Mittelkreis[%]"
              name="middleCircleSize"
              value="40"
              placeholder="40"
            />
          </div>
        </div>

<!-- ==================== Animation Wheel ======================== -->
        <div class="group">
          <h2> Animationen Wheel</h2>
          <div class="double-wide">
            <FormulateInput
              type="number"
              name="animDuration"
              label="Zeit der Glücksrad-Animation [s]"
              value="6"
            />
            <FormulateInput
              type="number"
              name="numberOfRotations"
              label="Zahl der Umdrehungen"
              value="5"
            />
            <FormulateInput
              type="number"
              name="waitBeforeDone"
              label="Zeit bis Preis/Neustart [s]"
              value="1"
              placeholder="3"
            />
          </div>
        </div>

<!-- ==================== cheatFrequency ======================== -->
<div class="group">
          <h2> 
            <FormulateInput
              name="cheatFrequency"
              type="checkbox"
              label="cheat Frequency"
              value="uncheck"
            />
          </h2>

          <h3 v-if="wheelData.cheatFrequency == true">
            {{ getSumOfFrequ }} verteilte WarscheinlichkeitsProzente   .....  (muss 100 % sein ...)
          </h3>
      </div>

<!-- ==================== SOUND ======================== -->
<div class=" group bgcolor2">
          <h2> 
            <FormulateInput
              name="useSound"
              type="checkbox"
              label="use Sound"
              value="check"
            />
          </h2>
          <div v-if="wheelData.useSound === true" class="double-wide ">
            <FormulateInput
              name="startsound"
              type="text"
              label="start sound"
              value="/snd/TURNWheel.mp3"
            />
            <FormulateInput
              name="stopsound"
              type="text"
              label="stop sound"
              value="/snd/testsound.wav"
            />
          </div>
        </div>

<!-- ==================== Layout Wheel ======================== -->

        <div class="group">
          <h2> Hintergrund/Nubsi/Mittel-Logo/Schrift </h2>
          <hr>
          <div class="double-wide">
            <FormulateInput
              name="bg"
              type="text"
              label="Hintergrund Bild"
              placeholder="/content/LuckyWheel/GR_Hintergrund.png"
            />
            <FormulateInput
              name="bgColor"
              type="color"
              label="backgroundColor"
              value="#ffffff"
              placeholder= "#ffffff"
            />
          </div>

          <div class="double-wide">
            <FormulateInput
              name="pfeil"
              type="text"
              label="Nubsi Bild"
              placeholder="/img/logos/arrow.png"
            />
            <FormulateInput
              name="useRadImg"
              type="checkbox"
              label="use Zeiger Img"
            />
          </div>

          <FormulateInput
            name="logo"
            type="group"
          >
            <div class="double-wide">
              <FormulateInput
                name="src"
                type="text"
                label="Mittel-Logo"
                placeholder="/img/logos/centerimage.jpg"
              />
              <FormulateInput
                name="width"
                type="number"
                label="Groesse"
                placeholder="30"
              />
            </div>
          </FormulateInput>

          <div class="double-wide">   
            <FormulateInput
              name="font"
              type="text"
              label="Schriftart"
              placeholder="ariel die Meerjungfrau"
          />
            <FormulateInput
              name="fontsize"
              type="text"
              label="Schriftgroesse"
              placeholder="32"
            />
          </div>

        </div>

  <!-- ==================== Farben Wheel ======================== -->

        <div class="group bgcolor1">
          <h2> Farben/Dicke Umrandungen </h2>
          <hr>
          <h3> Mittelkreis </h3>
          <div class="double-wide">
            <FormulateInput
              name="middleColor"
              type="color"
              label="middleColor"
              placeholder="#ffffff"
              value="#ffffff"
            />
            <FormulateInput
              name="strokeColorInner"
              type="color"
              label="strokeColor"
              placeholder="#ffffff"
              value="#ffffff"
            />
            <FormulateInput
              name="strokeWidthInner"
              type="number"
              label="strokeWidth"
              placeholder="5"
              value="5"
            />
          </div>   
          <h3> Aussenkreis </h3>
          <div class="double-wide">
            <FormulateInput
              name="strokeColorOuter"
              type="color"
              label="strokeColor"
              placeholder= "#ffffff"
              value="#ffffff"
            />
            <FormulateInput
              name="strokeWidthOuter"
              type="number"
              label="strokeWidth (0 oder >= Feldumrandung)"
              placeholder="12"
              value="12"
            />
          </div>   
          <h3> Felderumrandung </h3>
          <div class="double-wide">
            <FormulateInput
              name="strokeColorBorder"
              type="color"
              label="strokeColor"
              placeholder= "#ffffff"
              value="#ffffff"
            />
            <FormulateInput
              name="strokeWidthBorder"
              type="number"
              label="strokeWidth"
              placeholder="5"
              value="0"
            />
          </div>   
        </div>

  <!-- ==================== Nicht skalierbare Bilder ======================== -->
        <h2>Statische Bilder</h2>
        <FormulateInput
          type="group"
          name="nichtSkalierbareBilder"
          class="group"
          :repeatable="true"
          :remove="true"
          add-label="+ Bild"
          #default="{ index }"
        >
          <strong class="price" v-text="index" />
          <div class="attendee">
            <FormulateInput
              type="number"
              label="posX"
              name="posX"
              value="0"
              placeholder="-50"
            />
            <FormulateInput
              type="number"
              label="posY"
              name="posY"
              value="0"
              placeholder="20"
            />
            <FormulateInput
              type="number"
              label="Size"
              name="size"
              value="96"
              placeholder="50"
            />
          </div>

          <div class="attendee double-wide">
            <FormulateInput
              name="src"
              type="text"
              label="Bild src"
              placeholder="/img/logos/centerimage.jpg"
            />
          </div>
        </FormulateInput>

<!-- ==================== Felder ======================== -->
        <h2>Felder</h2>
        <FormulateInput
          type="group"
          name="felder"
          class="group"
          :repeatable="true"
          :remove="true"
          add-label="+ 1 Tortenstück"
          #default="{ index }"
        >
          <strong class="price" v-text="index" />
          <div class ="group">
            <div class="attendee">
              <FormulateInput
                name="bgColor"
                type="text"
                label="Hintergrund Farbe"
                value="#000000"
              />
              <FormulateInput
                type="color"
                name="color"
                label="SchriftFarbe"
                value="#ffffff"
              />
              <FormulateInput
                name="value"
                type="text"
                validation=""
                label="Gewinn Text"
              />
              <FormulateInput
                name="id"
                type="number"
                label="id"
                :value="index"
                validation=""
              />
            </div>
            <div class="attendee double-wide">
              <FormulateInput
                name="pricelogo"
                type="text"
                label="Logo des Preises"
                placeholder="Hier Dateipfad angeben"
              />
            </div>
          </div>

<!-- Begin Preisanimation -->
          <div class="group">
            <FormulateInput
              name="useAnimation"
              type="checkbox"
              label="Mit Preisanimation"
            />
             <FormulateInput
              type="text"
              name="animationlogo"
              label="Logo der Animation"
              placeholder="Hier Dateipfad angeben"
            />

            <div class="attendee"> 
              <FormulateInput
                type="number"
                name="abstandr"
                label="Abstand von der Mitte"
                value="30"
                placeholder="30"
              />
              <FormulateInput
                type="number"
                name="width"
                label="Größe des Logos"
                value="8"
                placeholder="8"
              />
            </div>
          </div>
<!-- END Preisanimation -->

<!-- Begin Variable Haeufigkeit  -->
          <div v-if="wheelData.cheatFrequency == true" class="group bgcolor5" id="relativeWarscheinlichkeit">
            <VTooltip 
              class="ttTip"
              placement="left">
              <a>( i )</a>

              <template #popper>
                man kann auch die relativen Wahrscheinlichkeiten per hand angeben. <br>
                man beachte hierbei : <br/>
                * wert ist in % <br>
                * alle Werte Aufsummiert sollten 100 ergeben ! :) 
              </template>
            </VTooltip>

             <FormulateInput
              type="number"
              name="relativeFrequency"
              label="Warscheinlichkeit in %"
              value=0
              placeholder="0"
            />

            <!-- 
            <div class="attendee"> 
              Summierte Wahrscheinlichkeiten (soll = 100 ) {{ sumFrequ }}
            </div> -->

          </div>
<!-- END Variable Haeufigkeit -->

<!-- start FrageAntwort -->
          <div class="group"> 
            <VTooltip 
              class="ttTip"
              placement="left">
              <a>( i )</a>

              <template #popper>
                FrageAntwort / Quiz erscheint wenn ausgewählt
              </template>
            </VTooltip>

            <FormulateInput
              name="useFrageDialog"
              type="checkbox"
              value="uncheck"
              label="Mit Frage Dialog"
            /> 

            <FormulateInput
              name="frageJsonText"
              type="textarea"
              value="JSON"
              placeholder="Paste Question JSON here"
            />
          </div>
<!-- END FrageAntwort -->

        </FormulateInput>
<!-- END - ein Feld (template) -->

<!-- ========================== Kommentar ======================== -->
        <div class=" group">
          <h2>Kommentar</h2>
          <FormulateInput
            type="textarea"
            name="luckyJsonText"
            placeholder="Senf"
          />
        </div>

<!-- ======================= Start with  QR-Code ======================== -->
        <div class="group qrcode bgcolor3" >
          <h2>  
            <FormulateInput
              name="useQR"
              type="checkbox"
              label="Start via QR-Code"
              value="check"
              class="densed"
            />
        </h2>

          <FormulateInput
            name="QRCode"
            type="group"
            v-if="wheelData.useQR == true"
          >
            <div class="double-wide" 
            >
              <FormulateInput
                type="number"
                label="posX"
                name="posX"
                value="0"
                placeholder="0"
              />
              <FormulateInput
                type="number"
                label="posY"
                name="posY"
                value="0"
                placeholder="0"
              />
              <FormulateInput
                type="number"
                label="Size"
                name="size"
                value="8"
                placeholder="8"
              />
            </div>
            <FormulateInput
              name="src"
              type="text"
              label="Background image SRC"
              placeholder="/img/qr/test.png"
              value="/img/n.png"
            />
          </FormulateInput>
        </div>
  <!-- ==================== END Start with  QR-Code ======================== -->
  
  <!-- ====================== Mobile Starter ======================== -->
        <FormulateInput
          name="mobileStartPage"
          type="group"
          class="group bgcolor4 mobilestarter"
        >
          <h2> Mobile Starter </h2>
          <FormulateInput
            name="src"
            type="text"
            label="BG src"
            placeholder="img/qr/test"
          />
        </FormulateInput>
  <!-- ======================= END Mobile Starter ======================== -->

      </FormulateForm>
<!-- ====================== END FORM ======================== -->

      <div  v-if="showJson===true">
        <pre
          class="code"
          v-text="wheelData"
        />

      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import { mapActions } from "vuex";

import GluecksRad from "@/components/GluecksRad.vue"
import axios from 'axios';
import Version from "@/components/devcomps/Infos.vue"

export default {
  name: 'LuckyEdit',
  components: {
    GluecksRad,
    Version
  },

  data() {
    return {
        showDevice: true,
        showJson: true,
        luckyName: 'test',
        luckyJsonText: '',
        textobj: ['logo','obj'],
        versionEditor: "0.4.0"
    }
  },

  computed: {
    wheelData: {
      get () {
        return this.wheeldata
      },
      set (value) {
        this.$store.commit('setWheelData', value)
      },
    },
      
    getSumOfFrequ: function () { 
      if (!this.wheeldata.cheatFrequency) {return 0}

      let count = 0
        for (let i = 0; i < this.wheeldata.felder.length; i++) {
          count = count + Number(this.wheeldata.felder[i].relativeFrequency)
        }
        return count
    },  

    ...mapGetters([
      'VERSION',
      'productiveUrl',
      'datum',
      'projectFileNames',
      'versionDataStructure',
      'wheeldata',
    ]),
  },

  async created () {
    console.log(" LuckyEdit -> created - begin ... ASYNC ??? --- check it ... !!!! ")
    await this.loadLuckyFromLocalStorage()
    await this.loadProjectNamesFromServer();
    console.log(" LuckyEdit -> created - END  ... ASYNC ??? --- check it ... !!!! ")
   },

  // mounted() {
  // },

  methods: {
    ...mapActions([
      'loadLuckyFromLocalStorage',
      'loadLuckyFromServer',
      'loadProjectNamesFromServer',
      'saveLuckyToLocalStorage',
      'saveLuckyToServer',
      'setWheelData',
      'uploadFile',
    ]),
    
    getVorschauUrl: function () {
      return window.location.origin + "/luckyw"
    }

  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.ttTip {
  position: absolute;
  right: 1vw;
  font-size: 1.1rem;
}

h1 {
  font-size: 4vh;
}
h2 {
  font-size: 2vh;
}

.bgcolor0 {
   background: #eeeeee;
}

.bgcolor1 {
   background: #dddddd;
}

.bgcolor2 {
   background: #f3f4fa;
}

.bgcolor3 {
   background: #fffff0;
}

.bgcolor4 {
   background: #ffccff;
}

.bgcolor5 {
   background: #c43030;
}

.flowRow {
  display: flex;
  margin: auto;
}

.flowRow div {
    margin: auto;
}

.densed {
  margin: initial;
}

a {
  margin: auto;
}

.header {
  margin: 1rem 1rem 0 2rem;
}

.login-form {
  padding: 2em;
  border: 1px solid #a8a8a8;
  border-radius: .5em;
  /* max-width: 47vw;
  width: 47vw; */
  width: 100vw;
  box-sizing: border-box;
  margin: 1vw;
}
.form-title {
  margin-top: 0;
}
.login-form::v-deep .formulate-input .formulate-input-element {
  max-width: none;
}
@media (min-width: 420px) {
  .double-wide {
    display: flex;
  }
  .double-wide .formulate-input {
    flex-grow: 1;
    width: calc(50% - .5em);
  }
  .double-wide .formulate-input:first-child {
    margin-right: .5em;
  }
  .double-wide .formulate-input:last-child {
    margin-left: .5em;
  }
}

.editContainer{
  display: flex;
}

pre {
  right: 0;
  top: 0;
  width: 40vw;
  align-content: center;
  background-color: aliceblue;
  padding: 2vw;
  margin: 1vw;
}

.group {
  padding: 0 2em;
  border: 1px solid #a8a8a8;
  border-radius: .5em;
  /* max-width: 45vw; */
  max-width: 95vw;
  box-sizing: border-box;
  margin: 1vw 0;
}

.form-wrapper {
  padding: 2em;
  border: 1px solid #a8a8a8;
  border-radius: .5em;
  box-sizing: border-box;
}
@media (min-width: 650px) {
  .attendee {
    display: flex;
  }
}

@media (min-width: 720px) {
  .attendee {
    display: block;
  }
}

@media (min-width: 850px) {
  .attendee {
    display: flex;
  }
  .attendee .formulate-input {
    margin-right: 1.5em;
  }
}
.attendee .formulate-input {
  margin-right: 2em;
  margin-bottom: 0;
}

strong {
  display: block;
  margin: 1em 0;
}

strong.price {
  margin-top: .25em;
  margin-bottom: 0;
  display: inline-flex;
  align-items: center;
}

code {
  margin-top: 2em;
}

.ttPage {
  overflow: scroll;
  height: 100vh;
}

.qrcode {
  background-color: aquamarine;
}

.mobilestarter {
  background-color: lightsalmon;
}

.formulate-input {
  margin-bottom: 1em;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .formulate-input .formulate-input-label {
    display: block;
    line-height: 1.5;
    font-size: .9em;
    font-weight: 600;
    margin-bottom: .1em; }
    .formulate-input .formulate-input-label--before + .formulate-input-help--before {
      margin-top: -.25em;
      margin-bottom: .75em; }
  .formulate-input .formulate-input-element {
    margin-bottom: .1em; }
  .formulate-input .formulate-input-help {
    color: #6d6d6d;
    font-size: .7em;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: .25em; }
  .formulate-input .formulate-input-errors {
    list-style-type: none;
    padding: 0;
    margin: 0; }
  .formulate-input .formulate-input-error,
  .formulate-input .formulate-file-upload-error {
    color: #960505;
    font-size: .8em;
    font-weight: 300;
    line-height: 1.5;
    margin-bottom: .25em; }
  /* .formulate-input:last-child {
    margin-bottom: 0; } */
  .formulate-input[data-classification='text'] input {
    appearance: none;
    border-radius: .3em;
    border: 1px solid #cecece;
    box-sizing: border-box;
    background-color: transparent;
    font-size: .9em;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    padding: .5em .75em;
    display: block;
    width: 100%;
    font-weight: 400;
    line-height: 1.2em;
    margin: 0; }
    .formulate-input[data-classification='text'] input::placeholder {
      color: #a8a8a8;
      line-height: normal; }
    .formulate-input[data-classification='text'] input:focus {
      outline: 0;
      border: 1px solid #41b883; }
  .formulate-input[data-classification='text'] .formulate-input-element--date input,
  .formulate-input[data-classification='text'] .formulate-input-element--month input,
  .formulate-input[data-classification='text'] .formulate-input-element--week input,
  .formulate-input[data-classification='text'] .formulate-input-element--datetime-local input {
    min-height: 2.2em; }
  .formulate-input[data-classification='text'] .formulate-input-element--search {
    position: relative; }
    .formulate-input[data-classification='text'] .formulate-input-element--search::before {
      content: '';
      width: 2em;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72.99 72.9"><path fill="%236d6d6d" d="M71.77,66,53.87,48.1A29.94,29.94,0,1,0,30,60a29.52,29.52,0,0,0,18.1-6.1l18,17.8A4,4,0,0,0,69,72.9a4.18,4.18,0,0,0,2.9-1.2A4.09,4.09,0,0,0,71.77,66ZM30.07,51.9a21.9,21.9,0,1,1,15.5-37.4A21.37,21.37,0,0,1,52,30a22,22,0,0,1-6.4,15.5A21.54,21.54,0,0,1,30.07,51.9Z"/></svg>');
      background-size: 1em 1em;
      background-repeat: no-repeat;
      background-position: center;
      pointer-events: none; }
    .formulate-input[data-classification='text'] .formulate-input-element--search input {
      padding-left: 2em; }
  .formulate-input[data-classification='text'] input[type="color"] {
    height: 1.1em;
    box-sizing: content-box;
    width: auto;
    min-width: 5em; }
    .formulate-input[data-classification='text'] input[type="color"]::-webkit-color-swatch-wrapper {
      padding: 0 0 0 1.5em;
      display: flex;
      align-items: center;
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79.17 79.17"><path fill="%236d6d6d" d="M40.8,22.92c-3.4-3.4-4.76-8.44-1-12.24s8.84-2.44,12.24,1c5-5,10.69-13.33,18.81-11.31a11,11,0,0,1,7.62,14.34c-1.26,3.45-4.63,6.06-7.16,8.59-.92.93-3,2.26-3.46,3.46-.42,1,1.82,2.63,2.36,4a8,8,0,0,1-12.42,9.19c-.21-.16-1.35-1.51-1.59-1.51s-.83.83-1,1L49.71,44.9,32.43,62.18c-3.37,3.38-6.6,7.14-10.26,10.21a11,11,0,0,1-4.48,2.28c-1.25.3-3.11-.22-4.18.18-1.28.48-2.42,2.65-3.68,3.4-6.05,3.61-12.64-4-8.46-9.57.73-1,2.53-1.92,3-3a14.46,14.46,0,0,0-.09-2.52,10.75,10.75,0,0,1,3.14-6.77c.92-1,1.93-1.93,2.89-2.9Zm4.4-1.5c4.19,4,8.24,8.24,12.36,12.36,2.06,2.06,5,5.59,8,2.61,4.65-4.62-5-6.8-2.42-10.78C66.3,20.7,76.4,16.48,74.84,9.45,73.62,4,67.12,2.78,63.29,6.32c-2.55,2.36-4.93,4.94-7.39,7.4-.79.78-1.8,2.28-2.88,2.73-2.14.88-3.4-1.62-4.79-2.77-2.58-2.14-6.89-.82-6.53,3C41.89,18.68,43.87,20.09,45.2,21.42Zm-1.45,4.44L27.82,41.79C22,47.57,15.89,53.14,10.41,59.2a8.23,8.23,0,0,0-1.44,2c-.93,2,.25,4.14-.5,6S4.92,69.94,4.3,72a2.34,2.34,0,0,0,2.56,3c1.11-.17,2-1.33,2.71-2.07a11.17,11.17,0,0,1,2.08-2c1.68-.94,4,.17,5.93-.57C20,69.41,22,66.73,23.76,65L34.42,54.3,53.3,35.42Z"/></svg>');
      background-repeat: no-repeat;
      background-size: .9em .9em;
      background-position: left .1em; }
    .formulate-input[data-classification='text'] input[type="color"]::-webkit-color-swatch {
      display: block;
      height: 1em;
      border-radius: .2em;
      border: 0;
      flex: auto; }
    .formulate-input[data-classification='text'] input[type="color"]::-moz-color-swatch {
      display: block;
      height: 1em;
      border-radius: .2em;
      border: 0;
      flex: auto; }
  .formulate-input[data-classification='slider'] .formulate-input-element--range {
    display: flex;
    align-items: center; }
  .formulate-input[data-classification='slider'] .formulate-input-element-range-value {
    font-size: .9em;
    line-height: 1;
    margin-left: .5em;
    background-color: #efefef;
    padding: .25em .3em;
    border-radius: .25em;
    color: #6d6d6d;
    font-variant-numeric: tabular-nums; }
  .formulate-input[data-classification='slider'] input {
    appearance: none;
    width: 100%;
    font-size: 1em;
    padding: .5em 0; }
    .formulate-input[data-classification='slider'] input:focus {
      outline: 0; }
    .formulate-input[data-classification='slider'] input::-webkit-slider-thumb {
      cursor: pointer;
      appearance: none;
      width: 1em;
      height: 1em;
      border-radius: 1em;
      border: 0;
      background-color: #41b883;
      margin-top: calc(-.5em + 2px); }
    .formulate-input[data-classification='slider'] input::-moz-range-thumb {
      cursor: pointer;
      appearance: none;
      width: 1em;
      height: 1em;
      border-radius: 1em;
      border: 0;
      background-color: #41b883;
      margin-top: calc(-.5em + 2px); }
    .formulate-input[data-classification='slider'] input::-ms-thumb {
      cursor: pointer;
      appearance: none;
      width: 1em;
      height: 1em;
      border-radius: 1em;
      border: 0;
      background-color: #41b883;
      margin-top: calc(-.5em + 2px); }
    .formulate-input[data-classification='slider'] input::-webkit-slider-runnable-track {
      appearance: none;
      width: 100%;
      height: 4px;
      background-color: #efefef;
      border-radius: 3px;
      margin: 0;
      padding: 0; }
    .formulate-input[data-classification='slider'] input::-moz-range-track {
      appearance: none;
      width: 100%;
      height: 4px;
      background-color: #efefef;
      border-radius: 3px;
      margin: 0;
      padding: 0; }
  .formulate-input[data-classification='textarea'] textarea {
    appearance: none;
    border-radius: .3em;
    border: 1px solid #cecece;
    box-sizing: border-box;
    background-color: transparent;
    font-size: .9em;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    padding: .75em;
    display: block;
    width: 100%;
    font-weight: 400;
    line-height: 1.2em;
    margin: 0; }
    .formulate-input[data-classification='textarea'] textarea::placeholder {
      color: #a8a8a8;
      line-height: normal; }
    .formulate-input[data-classification='textarea'] textarea:focus {
      outline: 0;
      border: 1px solid #41b883; }
  .formulate-input[data-classification='button'] button {
    appearance: none;
    border-radius: .3em;
    border: 1px solid #cecece;
    box-sizing: border-box;
    background-color: transparent;
    font-size: .9em;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    padding: .75em;
    display: block;
    width: 100%;
    font-weight: 400;
    line-height: 1.2em;
    margin: 0;
    border-color: #41b883;
    background-color: #41b883;
    color: white;
    min-width: 0;
    width: auto;
    font-weight: bold;
    cursor: pointer;
    display: inline-flex;
    align-items: center; }
    .formulate-input[data-classification='button'] button::placeholder {
      color: #a8a8a8;
      line-height: normal; }
    .formulate-input[data-classification='button'] button:focus {
      outline: 0;
      border: 1px solid #41b883; }
    .formulate-input[data-classification='button'] button[disabled] {
      background-color: #cecece;
      border-color: #cecece; }
      .formulate-input[data-classification='button'] button[disabled]:active {
        background-color: #cecece;
        border-color: #cecece; }
    .formulate-input[data-classification='button'] button[data-ghost] {
      color: #41b883;
      background-color: transparent;
      border-color: currentColor; }
    .formulate-input[data-classification='button'] button[data-minor] {
      font-size: .75em;
      display: inline-block; }
    .formulate-input[data-classification='button'] button[data-danger] {
      background-color: #960505;
      border-color: #960505; }
    .formulate-input[data-classification='button'] button[data-danger][data-ghost] {
      color: #960505;
      background-color: transparent; }
    .formulate-input[data-classification='button'] button:active {
      background-color: #64c89b;
      border-color: #64c89b; }
  .formulate-input[data-classification='select'] .formulate-input-element {
    position: relative; }
    .formulate-input[data-classification='select'] .formulate-input-element::before {
      content: '';
      width: 0;
      height: 0;
      border: .3em solid transparent;
      border-top-color: #cecece;
      border-bottom-width: 0;
      top: 50%;
      margin-top: -.1em;
      right: 1em;
      position: absolute; }
    .formulate-input[data-classification='select'] .formulate-input-element[data-multiple]::before {
      display: none; }
  .formulate-input[data-classification='select'] select {
    appearance: none;
    border-radius: .3em;
    border: 1px solid #cecece;
    box-sizing: border-box;
    background-color: transparent;
    font-size: .9em;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    padding: .75em;
    display: block;
    width: 100%;
    font-weight: 400;
    line-height: 1.2em;
    margin: 0;
    padding-right: 2em; }
    .formulate-input[data-classification='select'] select::placeholder {
      color: #a8a8a8;
      line-height: normal; }
    .formulate-input[data-classification='select'] select:focus {
      outline: 0;
      border: 1px solid #41b883; }
    .formulate-input[data-classification='select'] select option {
      color: #000000; }
      .formulate-input[data-classification='select'] select option:disabled {
        color: #a8a8a8; }
    .formulate-input[data-classification='select'] select[multiple] {
      padding: 0;
      overflow-y: auto; }
      .formulate-input[data-classification='select'] select[multiple] option {
        padding: 0.75em; }
    .formulate-input[data-classification='select'] select[data-placeholder-selected] {
      color: #a8a8a8; }
  .formulate-input[data-classification='box'] .formulate-input-wrapper {
    display: flex;
    align-items: center; }
  .formulate-input[data-classification='box'] .formulate-input-element {
    overflow: hidden;
    display: flex;
    align-items: center; }
    .formulate-input[data-classification='box'] .formulate-input-element input {
      position: absolute;
      left: -999px;
      opacity: 0;
      pointer-events: none; }
    .formulate-input[data-classification='box'] .formulate-input-element-decorator {
      display: block;
      width: 1em;
      height: 1em;
      border-radius: .25em;
      border: 1px solid #cecece;
      position: relative; }
      .formulate-input[data-classification='box'] .formulate-input-element-decorator::before {
        content: '';
        display: block;
        background-size: contain;
        background-position: right;
        width: calc(100% - .125em);
        height: calc(100% - .125em);
        box-sizing: border-box;
        position: absolute;
        top: .0625em;
        left: .0625em; }
    .formulate-input[data-classification='box'] .formulate-input-element[data-type="radio"] .formulate-input-element-decorator {
      border-radius: 1em; }
      .formulate-input[data-classification='box'] .formulate-input-element[data-type="radio"] .formulate-input-element-decorator::before {
        border-radius: 1em;
        width: calc(100% - .5em);
        height: calc(100% - .5em);
        top: .25em;
        left: .25em; }
    .formulate-input[data-classification='box'] .formulate-input-element input[type="checkbox"]:checked ~ .formulate-input-element-decorator {
      border-color: #41b883; }
      .formulate-input[data-classification='box'] .formulate-input-element input[type="checkbox"]:checked ~ .formulate-input-element-decorator::before {
        background-color: #41b883;
        mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M8.76,56.2c-6.38-6.34,3.26-16,9.64-9.69L38,65.88,80.56,23.29c6.38-6.38,16.07,3.32,9.69,9.69L42.84,80.37a6.83,6.83,0,0,1-9.65,0Z"/></svg>'); }
    .formulate-input[data-classification='box'] .formulate-input-element input[type="radio"]:checked ~ .formulate-input-element-decorator {
      border-color: #41b883; }
      .formulate-input[data-classification='box'] .formulate-input-element input[type="radio"]:checked ~ .formulate-input-element-decorator::before {
        background-color: #41b883; }
    .formulate-input[data-classification='box'] .formulate-input-element input:focus ~ .formulate-input-element-decorator {
      border-color: #41b883; }
  .formulate-input[data-classification='box'] .formulate-input-label--after {
    margin-left: .5em; }
  .formulate-input[data-classification='box'] .formulate-input-label--before {
    margin-right: .5em; }
  .formulate-input[data-classification='group'] .formulate-input-group-item {
    margin-bottom: .5em; }
  .formulate-input[data-classification='group'] > .formulate-input-wrapper > .formulate-input-label {
    margin-bottom: .5em; }
  .formulate-input[data-classification='group'] [data-is-repeatable] {
    padding: 1em;
    border: 1px solid #efefef;
    border-radius: .3em; }
    .formulate-input[data-classification='group'] [data-is-repeatable] .formulate-input-grouping {
      margin: -1em -1em 0 -1em; }
    .formulate-input[data-classification='group'] [data-is-repeatable] .formulate-input-group-repeatable {
      padding: 1em 3em 1em 1em;
      border-bottom: 1px solid #efefef;
      position: relative; }
      .formulate-input[data-classification='group'] [data-is-repeatable] .formulate-input-group-repeatable-remove {
        position: absolute;
        display: block;
        top: calc(50% - .65em + .5em);
        width: 1.3em;
        height: 1.3em;
        background-color: #cecece;
        right: .85em;
        border-radius: 1.3em;
        cursor: pointer;
        transition: background-color .2s;
        overflow: hidden;
        text-indent: -1000px; }
        .formulate-input[data-classification='group'] [data-is-repeatable] .formulate-input-group-repeatable-remove[data-disabled] {
          opacity: .2;
          pointer-events: none; }
        .formulate-input[data-classification='group'] [data-is-repeatable] .formulate-input-group-repeatable-remove::before, .formulate-input[data-classification='group'] [data-is-repeatable] .formulate-input-group-repeatable-remove::after {
          content: '';
          position: absolute;
          top: calc(50% - .1em);
          left: .325em;
          display: block;
          width: .65em;
          height: .2em;
          background-color: white;
          transform-origin: center center;
          transition: transform .25s; }
        @media (pointer: fine) {
          .formulate-input[data-classification='group'] [data-is-repeatable] .formulate-input-group-repeatable-remove:hover {
            background-color: #dc2c2c; }
            .formulate-input[data-classification='group'] [data-is-repeatable] .formulate-input-group-repeatable-remove:hover::after, .formulate-input[data-classification='group'] [data-is-repeatable] .formulate-input-group-repeatable-remove:hover::before {
              height: .2em;
              width: .75em;
              left: .25em;
              top: calc(50% - .075em); }
            .formulate-input[data-classification='group'] [data-is-repeatable] .formulate-input-group-repeatable-remove:hover::after {
              transform: rotate(45deg); }
            .formulate-input[data-classification='group'] [data-is-repeatable] .formulate-input-group-repeatable-remove:hover::before {
              transform: rotate(-45deg); } }
      .formulate-input[data-classification='group'] [data-is-repeatable] .formulate-input-group-repeatable:last-child {
        margin-bottom: 1em; }
  .formulate-input[data-classification="file"] .formulate-input-upload-area {
    width: 100%;
    position: relative;
    padding: 2em 0; }
    .formulate-input[data-classification="file"] .formulate-input-upload-area input {
      cursor: pointer;
      appearance: none;
      opacity: 0;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 5; }
    .formulate-input[data-classification="file"] .formulate-input-upload-area[data-has-files] {
      padding: 0; }
      .formulate-input[data-classification="file"] .formulate-input-upload-area[data-has-files] input {
        display: none; }
    .formulate-input[data-classification="file"] .formulate-input-upload-area-mask {
      border-radius: .4em;
      position: absolute;
      pointer-events: none;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border: 2px dashed #a8a8a8;
      z-index: 2; }
      .formulate-input[data-classification="file"] .formulate-input-upload-area-mask::before {
        content: '';
        background-color: #a8a8a8;
        mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 58"><path d="M29,58A29,29,0,1,0,0,29,29,29,0,0,0,29,58ZM29,4A25,25,0,1,1,4,29,25,25,0,0,1,29,4Z"/><polygon points="27 22 27 44.4 31 44.4 31 22 41.7 31.1 44.3 28.1 29 15 13.7 28.1 16.3 31.1 27 22"/></svg>');
        mask-repeat: no-repeat;
        mask-position: center;
        width: 2em;
        height: 2em;
        position: absolute;
        pointer-events: none; }
    .formulate-input[data-classification="file"] .formulate-input-upload-area input:focus ~ .formulate-input-upload-area-mask,
    .formulate-input[data-classification="file"] .formulate-input-upload-area input:hover ~ .formulate-input-upload-area-mask,
    .formulate-input[data-classification="file"] .formulate-input-upload-area input[data-is-drag-hover] ~ .formulate-input-upload-area-mask {
      border-color: #41b883; }
      .formulate-input[data-classification="file"] .formulate-input-upload-area input:focus ~ .formulate-input-upload-area-mask::before,
      .formulate-input[data-classification="file"] .formulate-input-upload-area input:hover ~ .formulate-input-upload-area-mask::before,
      .formulate-input[data-classification="file"] .formulate-input-upload-area input[data-is-drag-hover] ~ .formulate-input-upload-area-mask::before {
        background-color: #41b883; }
  .formulate-input[data-classification="file"] .formulate-files {
    list-style-type: none;
    margin: 0;
    padding: 0; }
    .formulate-input[data-classification="file"] .formulate-files .formulate-file-add {
      appearance: none;
      display: block;
      min-width: 75px;
      border: 1px solid #41b883;
      color: #41b883;
      padding: 5px;
      border-radius: 5px;
      text-align: center;
      position: relative;
      font-size: 13px;
      background-color: transparent;
      cursor: pointer;
      margin: .5em 0; }
      .formulate-input[data-classification="file"] .formulate-files .formulate-file-add input {
        position: absolute;
        display: block;
        cursor: pointer; }
        .formulate-input[data-classification="file"] .formulate-files .formulate-file-add input::-webkit-file-upload-button {
          display: none; }
    .formulate-input[data-classification="file"] .formulate-files .formulate-file-progress {
      background-color: #cecece;
      height: .3em;
      border-radius: 1.25em;
      width: 5em;
      overflow: hidden;
      position: relative;
      flex: 0 0 5em;
      right: .75em;
      transition: height .25s, width .25s, flex-basis .25s;
      z-index: 2; }
      .formulate-input[data-classification="file"] .formulate-files .formulate-file-progress::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
        opacity: 0;
        transform: scale(0.08);
        background-color: #ffffff;
        mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M8.76,56.2c-6.38-6.34,3.26-16,9.64-9.69L38,65.88,80.56,23.29c6.38-6.38,16.07,3.32,9.69,9.69L42.84,80.37a6.83,6.83,0,0,1-9.65,0Z"/></svg>');
        mask-size: 77%;
        mask-repeat: no-repeat;
        mask-position: center;
        z-index: 3; }
      .formulate-input[data-classification="file"] .formulate-files .formulate-file-progress[data-just-finished] {
        width: 1.25em;
        height: 1.25em;
        flex: 0 0 1.25em; }
        .formulate-input[data-classification="file"] .formulate-files .formulate-file-progress[data-just-finished]::before {
          transition: transform .25s .2s, opacity .25s .2s;
          transform: scale(1);
          opacity: 1; }
      .formulate-input[data-classification="file"] .formulate-files .formulate-file-progress[data-is-finished] {
        transition: height .25s, width .25s, left .25s, top, .25s, border-radius .25s;
        width: .3em;
        flex: 0 0 .3em;
        height: 100%;
        right: 0;
        border-radius: 0 .23em .23em 0; }
        .formulate-input[data-classification="file"] .formulate-files .formulate-file-progress[data-is-finished]::before {
          transition: opacity .1s;
          opacity: 0; }
      .formulate-input[data-classification="file"] .formulate-files .formulate-file-progress .formulate-file-progress-inner {
        background-color: #41b883;
        width: 1%;
        position: absolute;
        left: 0;
        bottom: 0;
        top: 0;
        z-index: 2; }
    .formulate-input[data-classification="file"] .formulate-files .formulate-file-name {
      padding-left: 1.5em;
      padding-right: 2em;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: auto; }
      .formulate-input[data-classification="file"] .formulate-files .formulate-file-name::before {
        position: absolute;
        left: .7em;
        top: 50%;
        margin-top: -.7em;
        background-color: #a8a8a8;
        content: '';
        mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64.06 83.59"><path d="M55.94,83.59a8.16,8.16,0,0,0,8.12-8.16V19.12a1.77,1.77,0,0,0-.52-1.25L46.21.59A1.69,1.69,0,0,0,45.14.08L44.69,0l-.18,0H8.13A8.18,8.18,0,0,0,0,8.16V75.41a8.16,8.16,0,0,0,8.13,8.16H55.94ZM46.68,6,58.11,17.38H46.68ZM3.52,75.43V8.16A4.64,4.64,0,0,1,8.13,3.52h35V19.16a1.75,1.75,0,0,0,1.76,1.74H60.55V75.43a4.65,4.65,0,0,1-4.61,4.65H8.13A4.65,4.65,0,0,1,3.52,75.43Z"/></svg>');
        mask-repeat: no-repeat;
        mask-size: contain;
        width: 1.25em;
        height: 1.25em;
        display: inline-block;
        margin-right: .5em; }
    .formulate-input[data-classification="file"] .formulate-files .formulate-file-remove {
      width: 1.25em;
      height: 1.25em;
      border-radius: 1em;
      border: 1px solid #a8a8a8;
      background-color: #a8a8a8;
      mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59.1 59.2"><path d="M1.6,57.7a5,5,0,0,0,3.5,1.5,4.85,4.85,0,0,0,3.5-1.5l21-21,21,21a5,5,0,0,0,3.5,1.5,4.85,4.85,0,0,0,3.5-1.5,5,5,0,0,0,0-7.1l-21-21,21-21a5,5,0,0,0,0-7.1,5,5,0,0,0-7.1,0l-21,21L8.6,1.7a5,5,0,0,0-7.1,0,5,5,0,0,0,0,7.1l21,21L1.6,50.7A4.83,4.83,0,0,0,1.6,57.7Z"/></svg>');
      mask-size: .6em;
      mask-repeat: no-repeat;
      mask-position: center;
      cursor: pointer;
      position: absolute;
      right: .75em;
      z-index: 1;
      transition: transform .25s; }
      @media (pointer: fine) {
        .formulate-input[data-classification="file"] .formulate-files .formulate-file-remove:hover {
          transform: scale(1.5); } }
    .formulate-input[data-classification="file"] .formulate-files li {
      display: block; }
      .formulate-input[data-classification="file"] .formulate-files li[data-has-error] .formulate-file-progress {
        background-color: #dc2c2c; }
      .formulate-input[data-classification="file"] .formulate-files li[data-has-preview] .formulate-file-name:before {
        display: none; }
      .formulate-input[data-classification="file"] .formulate-files li + li {
        margin-top: .5em; }
    .formulate-input[data-classification="file"] .formulate-files .formulate-file {
      appearance: none;
      border-radius: .3em;
      border: 1px solid #cecece;
      box-sizing: border-box;
      background-color: transparent;
      font-size: .9em;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      padding: .75em;
      display: block;
      width: 100%;
      font-weight: 400;
      line-height: 1.2em;
      margin: 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative; }
      .formulate-input[data-classification="file"] .formulate-files .formulate-file::placeholder {
        color: #a8a8a8;
        line-height: normal; }
      .formulate-input[data-classification="file"] .formulate-files .formulate-file:focus {
        outline: 0;
        border: 1px solid #41b883; }
      .formulate-input[data-classification="file"] .formulate-files .formulate-file ::-webkit-progress-bar {
        appearance: none;
        height: .5em;
        border-radius: .5em;
        overflow: hidden; }
    .formulate-input[data-classification="file"] .formulate-files .formulate-file-image-preview {
      width: 3em;
      height: 3em;
      flex: 0 0 3em;
      position: relative;
      z-index: 2;
      left: -1px;
      box-shadow: 0 0 0 1px #efefef;
      transition: transform .25s, box-shadow .25s, background-color .25s; }
      @media (pointer: fine) {
        .formulate-input[data-classification="file"] .formulate-files .formulate-file-image-preview:hover {
          transition-delay: .2s;
          transform: scale(3);
          background-color: #ffffff;
          box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1); } }
      .formulate-input[data-classification="file"] .formulate-files .formulate-file-image-preview img {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        object-fit: contain;
        transition: all .25s; }
  .formulate-input[data-classification="file"] [data-type="image"] .formulate-input-upload-area .formulate-input-upload-area-mask::before {
    mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 71.05"><path d="M82.89,0H7.1A7.12,7.12,0,0,0,0,7.11V64a7.11,7.11,0,0,0,7.1,7.1H82.9A7.11,7.11,0,0,0,90,64V7.11A7.12,7.12,0,0,0,82.89,0ZM69.28,39.35a5.44,5.44,0,0,0-8,0L50.58,50.74,32.38,30.88a5.31,5.31,0,0,0-7.92,0L4.74,52.4V7.11A2.37,2.37,0,0,1,7.11,4.74H82.9a2.37,2.37,0,0,1,2.36,2.37V56.3Z"/><circle cx="67.74" cy="22.26" r="8.53"/></svg>'); }

.formulate-form-errors {
  margin: .75em 0;
  padding: 0;
  list-style-type: none; }
  .formulate-form-errors:first-child {
    margin-top: 0; }
  .formulate-form-errors:last-child {
    margin-bottom: 0; }
  .formulate-form-errors .formulate-form-error {
    color: #960505;
    font-size: .9em;
    font-weight: 300;
    line-height: 1.5;
    margin-bottom: .25em; }
</style>
