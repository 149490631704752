<template>
	<div>
		<div id="Startscreen"
			class="Screen"
			v-bind:class="{ unsichtbar: startHidden }">
			<button type="button" @click="timer()">Start</button>
		</div>

		<div id="Countdownscreen"
			class="Screen"
			v-bind:class="{ unsichtbar: countdownHidden }">
			<div id="Counter"> {{ t }} </div>
		</div>

		<div id="Endscreen"
			class="Screen"
			v-bind:class="{ unsichtbar: endHidden }">
			<p>And now...<br>
			<strong>Enjoy</strong>
			</p>
		</div>
	</div>
</template>	
<script>

export default {
	name: 'SmoothieBike',

	data() {
		return {
			/** Länge des Countdowns in Sekunden */
			countdowntime: 15,
			/** Wartezeit für Restart in Milisekunden */
			timetowaitforrestart: 10000,
			
			t: -1,
			counter: null,

			startHidden: false,
			countdownHidden: false,
			endHidden: false,
		}
	},

	mounted () {
		this.reset()
	},

	methods: {
		/** Funktion wird durch Buttonclick aufgerufen.
		Holt den Countdownscreen in den Vordergrung.
		Ruft die Funktion countdown() alle 1000ms auf.
		*/
		timer: function() {
			this.hideAll();
			this.countdownHidden = false;
			this.countdown();
			this.counter = setInterval(this.countdown, 1000);
		},

		/** Wird durch die Funktion timer() aufgerufen.
		Zählt die Sekunden runter.
		Ruft die Funktion stopcount() auf, wenn Counter auf 0.
		*/
		countdown: function() {
			this.t = this.t - 1;
			if (this.t < 0) {
				this.stopcount();
			}
		},
		
		/** Wird durch die Funktion countdown() aufgerufen.
		Stoppt die Aufrufe durch die Funktion timer().
		Holt den Countdownscreen in den Vordergrund.
		Wartet (timetowaitforrestart)ms und ruft dann die Funtion reset() auf.
		*/
		stopcount: function() {
		clearInterval(this.counter);
		this.hideAll();
		this.endHidden = false;
			setTimeout(this.reset, this.timetowaitforrestart);	
		},

		/** Versteckt alle Screens */
		hideAll: function() {
			this.startHidden = true;
			this.countdownHidden = true;
			this.endHidden = true;
		},

		/** Setzt Counter zurück und holt den Startscreen in den Vordergrund */
		reset: function() {
			this.t = this.countdowntime;
			this.hideAll()
			this.startHidden= false;
		},
	} // end Methods
}
</script>

<style scoped>
	
	.Screen {
		width: 100vw;
		height: 100vh;
	}

	/* Testbilder: Eigene Ressourcen einfügen! */

	/* startbild.jpeg */
	#Startscreen {
		background-image: url("/img/startbild.jpeg");
		background-size: cover;
	}

	/* bike.gif */
	#Countdownscreen {
		background-image: url("/img/bike.gif");
		background-size: cover;
	}

	/* smoothies.png */
	#Endscreen {
		background-image: url("/img/smoothies.png");
		background-size: cover;
	}	

	/* Farben und Größe etc. für Button, Counter und Schriftzug hier ändern: 
	Vordefinierte Farben z.B hier https://www.w3schools.com/colors/colors_names.asp */
	button {
		background-color: Lime;
		color: AntiqueWhite;
		font-size: 6vw;
		font-family: sans-serif;
		position: fixed;
		width: 30%;
		height: 16%;
		top: 42%;
		left: 35%;
		text-align: center;
	}

	#Counter {
		/* background-color: Lime; */
		color: lime;
		font-size: 50vh;
		font-family: sans-serif;
		position: fixed;
		width: 30%;
		height: 16%;
		top: 25vh;
		left: 35%;
		text-align: center;
	}

	p {
		color: Coral;
		position: fixed;
		font-size: 6vw;
		top: 35%;
		left: 40%;
	}

	.unsichtbar {
		display: none;
	}
</style>


