<template>
	<div id="rootDiv">

		<div id="zler">
			{{ restzeitInS }}
		</div>

	</div>
</template>

<script>
  import {mapGetters} from "vuex";
  // import { mapActions } from "vuex";

export default {
  name: 'Countdown',

	props: ["startCounter"],
	emits: ["nextView"],

  components: {
  },

   data () {
    return {
		restzeit: -1,
		restzeitInS: -1,
		startzeit: null,
		time: null,
    }
  },
  
  computed:{
    ...mapGetters([
    ]),
  },

  created() {
	  this.restzeitInS = Math.ceil(this.startCounter / 1000)
  },

  mounted() {
	//   this.restzeitInS = Math.ceil(this.startCounter / 1000)
	  console.log(new Date().toLocaleString());
	  console.log("Restzeit in Sekunden = " + this.restzeitInS)
	  this.startTimer();
  },

  beforeDestroy () {
		this.stopcount();
  },

  methods: {
		  
		startTimer: function() {
			this.startzeit = new Date();
			this.countdown()
			this.time = setInterval(this.countdown, 200);
		},

		countdown: function () {
			const aktuellezeit = new Date(); 

			this.restzeit = this.startCounter + this.startzeit.getTime() - aktuellezeit.getTime();
			this.restzeitInS = Math.ceil(this.restzeit / 1000);

			if (this.restzeit <= 0 ) {
				this.restzeitInS = 0;
				this.stopcount();
				this.callParent()
			}

			// if(this.restzeit <= -1000){
			// 	this.$emit('switchView', this.nextMode);
			// }
			// if (this.restzeitInS <= 0 ) {
			// 	this.restzeitInS = 0
			// }
			// if(restzeit == 0){
			// }
		},

		callParent: function () {
			this.$emit('nextView');
		},

		stopcount: function() {
			console.log(" -> stopcount -> emits switchView " );
			clearInterval(this.time);
		},	
  }

}

</script>

<style scoped lang="scss">

	#rootDiv{
		position: absolute;
		// z-index: 1;
		width: 100vw;
		height: 100vh;
		display: flex;
		justify-content: center;
	}
	
	#zler {
		position: relative;
		top: 10vh;
		height: 31vh;
		width: 18vw;
		//background-color: rgba($color: #000000, $alpha: 0.5);
		display: flex;
		justify-content: center;
		align-items: center;
		align-items: center;
		font-size: 10em;
		font-family: Possling;
		color: #00834D;
	}
	
	



</style>
