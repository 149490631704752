<template>
  <div class="onlineMainScreen">

    <div 
      class="debug" 
      id="debugContainer" 
      @click="resetSensorData()"
      :style="sensorStatus()">
    >
      {{motionValueMax}}
    </div>
    
    <div id="title">
      Register to play on BubTables Interaction Module  (link zu datenschutz und so ... )
      <br> 
      and shake 
      {{gamename}}
    </div>

    <div id="inputContainer">
          Name : <input maxlength="15" name="new_name" type="text" v-model="new_name" v-bind:class="{'noData' : noData_name }" />
          <br> <br>
          email : <input maxlength="50" name="new_email" type="text" v-model="new_email" v-bind:class="{'noData' : noData_email }" />
    </div>
          

    <div class="buttonContainer">
        <div class="button" id="sendHSButton" v-on:click="validateForm('tentable')">START</div>
        <div class="button" id="clearButton" v-on:click="clearInput">CLEAR </div>
    </div>      

  </div>
</template>

<script >
  import {mapGetters} from "vuex";
  import { mapActions } from "vuex";
  import axios from 'axios'; 

export default {
  name: "RegisterViaQR",
  props: ['gamename'],
  components: {
  },
  data() { return {
      new_name: '',
      new_email: '',
      apiUrl: 'http://tentable.de/bub/api/queue.php',
      // apiUrl: 'http://10.116.0.130/bub/api/queue.php',
      axiosHeader: { headers: {} }  ,
      noData_name: false,
      noData_email: false,

      lastPos: {x: 0, y: 0, z: 0},
      deltaPos:  {x: 0, y: 0, z: 0},
      deltaMax:  {x: 0, y: 0, z: 0},

      motionValue: 0,
      motionValueMax: 0,
      
      /** threashold to start remote bzw send the registration  */
      simplStart: 1000
    }
  },

  created () {
  },

  mounted () {
     window.addEventListener("devicemotion", this.parseMotionEvent, true)
   console.log(this.$options.name + " -> mounted () ")
     },


  computed:{
    ...mapGetters([
    ]),
  },

  beforeDestroy: function(){
  },

  methods: {

    ...mapActions([
    ]),

    resetSensorData: function () {
      this.deltaMax =  {x: 0, y: 0, z: 0}
      this.motionValue = 0
      this.motionValueMax = 0
    },

    sendData: function() {
      let dataString = '?action=register&name=' + this.new_name + '&email=' + this.new_email + '&gameName=' + this.gamename
      let url = this.apiUrl + dataString
       let response = axios.get(url, this.axiosHeader)
        .then(response =>  console.log('sendData -> recieved: ' + response))
        .catch(err => { console.log(err.response); });
    },

    clearInput : function() {
          this.new_name = "";
          this.new_email = ""; 
        },
    
    validateForm: function() {
          // do not send if form is empty 
          if (this.new_name == "" ) {
            this.noData_name = true;
            console.log("name not set"); 
            return;
          } else  {
            this.noData_name = false;
            this.sendData()
            this.clearInput()
          }
    },


     parseMotionEvent: function(event) {
      this.handleMotionEvent(event.acceleration)

    },

    handleMotionEvent: function(event) {
      console.log(this.$options.name + " -> handleMotionEvent () ", event)
      this.deltaPos.x =   event.x - this.lastPos.x
      this.deltaPos.y =   event.y - this.lastPos.y
      this.deltaPos.z =   event.z - this.lastPos.z
      
      this.lastPos = event
      this.ok = 1

      if (this.deltaPos.x > this.deltaMax.x) {
        this.deltaMax.x = this.deltaPos.x
      }
      if (this.deltaPos.y > this.deltaMax.y) {
        this.deltaMax.y = this.deltaPos.y
      }
      if (this.deltaPos.z > this.deltaMax.z) {
        this.deltaMax.z = this.deltaPos.z
      }
       console.log(this.$options.name + " -> handleMotionEvent () : delta ", this.deltaPos)

      this.checkForSimpleStart(this.deltaPos)
    },

    checkForSimpleStart: function (vec) {
       this.motionValue = (vec.x * vec.x + vec.y * vec.y + vec.z * vec.z )
       if (this.motionValue > this.motionValueMax) {
         this.motionValueMax = this.motionValue
       }
      if ( this.motionValue > this.simplStart ) {
        this.sendData();
      }
    },

    sensorStatus: function () {
      let colString = "red"
      if (this.motionValueMax > 0) {
        colString = "orange"
      } 
      if (this.motionValueMax > this.simplStart) {
        colString = "green"
      }

       return { 'background-color' : colString};
    },

  },
}

</script>

<style scoped>

.noData {
    border: solid;
    border-color: red;
}

</style>
