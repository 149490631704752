<template>
  <div class="pageContainer">
    <div v-if="ok==true" id="noscores">
      <TopTen :eventName="'allianz'" id="irgendwas"></TopTen>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import TopTen from '@/views/windpark/components/TopTen.vue'; // @ is an alias to /src

import {mapGetters} from "vuex";
import { mapActions } from "vuex";


export default {
  name: 'SetUp',

  components: {
    TopTen,
  },

  data() {
    return {
            ok: false,
    }
  },

  created() {
  },

  async mounted() {
    await this.getEvents()
    this.ok = true
  },

  computed: {
     ...mapGetters([
    ]),
  },

  
  // watch: {
  //   arduinoData: function (_neu, _alt) {
  //     this.checkForRelaisAction(_neu.analogIn)
  //   }
  // },

  methods: {

    ...mapActions([
      'getEvents',
    ]),
  },
}
</script>

<style lang="scss">

body{
  background-color: rgba(255,255,255,0);
  //background-color: red;
}

#irgendwas{
  .highscoreContainer {
    
    width: 100%;
    font-size: 1rem;
    color: #fff;

    .hs-row {
      width: 100%;
      display: flex;
      padding: 0.6vw 1.4rem;
      font-size: 1.5rem;
      color: #003781;
      background-color:white;
      margin-top: 0.8vh;
      font-family: Allianz Neo;
      font-style: normal;
      font-weight: normal;
      // font-size: 30px;
    } 

    .top10{
      width: 100%;
    }

  }

  div {
    font-size: 5vh;
  }


  .hs-index {
      width: 25%;
      color: #003781;
      font-size: 1.5rem;
      
  }

  .hs-playerName {
    width: 45%;
    color: #003781;
    font-size: 1.5rem;
    }

  .hs-playerPoints {
    width: 25%;
    color: #003781;
    font-size: 1.5rem;
  }
  
  
  .hs-row:first-of-type {
    background-color: #00908D;
   .hs-index {
      width: 25%;
      color: white;
      font-size: 1.5rem;
      
  }

  .hs-playerName {
    width: 45%;
    color: white;
    font-size: 1.5rem;
    }

  .hs-playerPoints {
    width: 25%;
    color: white;
    font-size: 1.5rem;
  }

  }

}



.eventHeadline {
	display: none;
}

</style>
