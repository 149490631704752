<template>
  <div>

    <div id="NetRelais" class="ttcontainer">
      <h2
        @click="showDevice = !showDevice">WebRelais </h2>
      <div v-if="showDevice == true" >

        <div class="twoRows">
          <div class="ttRow">
            <div class="ttcontainer">
              <h3> settings </h3>
              IP der NetRelais Box : 
              {{myDevice.deviceIP}}
            </div>
            <div class="ttcontainer">
              <h3> Manuell Channel 1</h3>
              <div class="button"
                @click="powerOn(myDevice)"
                >
                an
              </div>
              <div class="button"
                @click="powerOff(myDevice)"
                >
                aus
              </div>
            </div>
            <!-- <div class="ttcontainer">
              <h3> Manuell Channel 2</h3>
              <div class="button"
                @click="powerOn('2')"
                >
                an
              </div>
              <div class="button"
                @click="powerOff(2)"
                >
                aus
              </div> -->
            <!-- </div> -->
        <div> 
          threshold1An : {{myDevice.deviceThresholdOn}} -- {{myDevice.myPower}}  -- {{myDevice.deviceThresholdOff}} : threshold1Aus 
        </div>


        <div class="ttcontainer">
          <h2 
           :class="myDevice.relaisIsPowerOn ? 'an' : 'aus'"> Interactive </h2>
          <div class="button"
              @click="stopPowerBike(myDevice)"
              v-if="myDevice.deviceIsInteractiveMode"
              >
            stop
          </div>
          <div class="button"
              @click="startPowerBike(myDevice)"
              v-else
              >
            start
          </div>
        </div>

        <div class="response">
          {{myDevice.deviceResponse}}
        </div>
        <div class="error"
          v-if="myDevice.deviceError !== ''">
          {{myDevice.deviceError}}
        </div>
      </div>

        <div class="ttRow">
          <img src="img/webRelais.png" class="deviceImg" />
        </div>
 
      </div>

      <iframe 
        :src="myDevice.deviceUrl" 
        class="deviceIFrame" />

    </div> 
    </div>

  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import { mapActions } from "vuex";
  import axios from 'axios';

export default {
  name: 'WebRelais',

   data() {
    return {
      showDevice: false,
      myDevice: {
          deviceName: 'webRelais1',
          deviceType: 'WebRelais',
          deviceIP: "10.116.1.198",
          deviceResponse: 'no response',
          deviceError: '',
          deviceThresholdOn: 150,
          deviceThresholdOff: 100,
          deviceIsInteractiveMode: false,
          relaisIsPowerOn: false,
          ArduinsensorPinNr: 0,
          myPower: 0,
          powerOnRequest: '/FF0101',
          powerOffRequest: '/FF0100',
      },
    }
  },

computed: {
    ...mapGetters([
      'axiosHeader',
    ]),
  },

  // mounted() {
  //   this.loopGetArduinoData()
  // },

  methods: {
    
    ...mapActions([
    ]),

   startPowerBike: function (_dev) {
      _dev.deviceIsInteractiveMode = true
    },
    stopPowerBike: function (_dev) {
      _dev.deviceIsInteractiveMode = false
    },

    powerOff: async function (_dev) {
       _dev.relaisIsPowerOn = false;

        let url = 'http://' + _dev.deviceIP + _dev.powerOffRequest // '/FF0100'

        let response = await axios.get(url, this.axiosHeader)    
        .then(response => this.setWebRelaisResponse(_dev, response))
        .catch(function(error) {
          console.log('error', error)
          _dev.deviceError = error
        }.bind(this)
      );

    },

     powerOn: async function (_dev) {
       console.log(this.$options.name + " -> powerOn")
        _dev.relaisIsPowerOn = true;
        let url = 'http://' + _dev.deviceIP + _dev.powerOnRequest // '/FF0101'

        let response = await axios.get(url, this.axiosHeader)    
        .then(response => this.setWebRelaisResponse(_dev, response))
        .catch(function(error) {
            console.log('error', error)
            _dev.deviceError = error
          }.bind(this)
      );
    },

    checkForRelaisAction: function (_dev, analogData) {
      _dev.myPower = analogData[_dev.sensorPinNr]
      if (_dev.relaisIsPowerOn && _dev.myPower < _dev.threshold1Aus) {
        this.powerOff(_dev)
      }
      if (!_dev.relaisIsPowerOn && _dev.myPower > _dev.threshold1An) {
        this.powerOn(_dev)
      }
    },

    setWebRelaisResponse: function (_dev, _response) {
      console.log(this.$options.name + " -> setWebRelaisResponse", _response)
      _dev.deviceResponse = _response
    }


  },

}

</script>

<style scoped>

  .sensorDubugContainer {
    display: flex;
  }
  .sensorDebugDiv {
    padding: 1vw;
    border: solid black 1px;
    color: blue;
    min-width: 2em;
  }

  .deviceIFrame {
    position: absolute;
    left: 100%;
    top: 0;
  }


</style>
